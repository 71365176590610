import { render, staticRenderFns } from "./ScotiabankSolicitante.vue?vue&type=template&id=e6534160&lang=html&"
import script from "./ScotiabankSolicitante.vue?vue&type=script&lang=js&"
export * from "./ScotiabankSolicitante.vue?vue&type=script&lang=js&"
import style0 from "./ScotiabankSolicitante.vue?vue&type=style&index=0&id=e6534160&prod&lang=css&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports