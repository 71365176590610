<template lang="html">

	<div class="">

    <div class="flex justify-end mt-4 mb-8">

      <button 
        class="btn btn-second" 
        @click="toggleSave()" 
        v-bind:hidden="!isDatoAdicionalEdit">Editar</button>
      <button 
        v-bind:hidden="isDatoAdicionalEdit" 
        class="btn btn-second" @click="registraAdicionales(); toggleSave()">Guardar adicionales</button>

    </div>

		<div class="accordion" id="accordionExample4">
			<!-- <div class="accordion-item"> Seccion Personales Inicio -->
				<!-- <h2 class="accordion-header" id="headingOne-dat">
					<button class="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne-dat" aria-expanded="true" aria-controls="collapseOne-dat">Personales</button>
				</h2> -->
				<!-- <div id="collapseOne-dat" class="accordion-collapse collapse" aria-labelledby="headingOne-dat" data-bs-parent="#accordionExample4">
					<div class="accordion-body accordion-scrolling mh-4"> -->
						<!-- <div class="row w-full">
							<div class="col-md-4">
								<span class="span-placeholder">Fecha de la solicitud</span>
								<input 
                  v-bind:disabled="isDatoAdicionalEdit" 
                  v-bind:class="[ isDatoAdicionalEdit ? 'vs-input bg-toggle-disable' : 'vs-input bg-toggle-enable']" 
                  label="Fecha de la solicitud" 
                  type="date" v-model="adicionales.FechaSolicitud" name="FechaSolicitud" />
							</div>
							<div class="col-md-4">
								<span class="span-placeholder">Lugar de la solicitud</span>
								<input 
                  v-bind:disabled="isDatoAdicionalEdit" 
                  v-bind:class="[ isDatoAdicionalEdit ? 'vs-input bg-toggle-disable' : 'vs-input bg-toggle-enable']" 
                  label="Lugar de la solicitud" 
                  v-model="adicionales.lugarSolicitud" name="name" />
							</div>

							<div class="col-md-4">
								<span class="span-placeholder" for="usosInmuebles">Uso de inmueble</span>
								<select 
                  v-bind:disabled="isDatoAdicionalEdit" 
                  name="cars" 
                  id="usosInmuebles" 
                  v-model="adicionales.usoInmueble" 
                  v-bind:class="[ isDatoAdicionalEdit ? 'vs-input bg-toggle-disable' : 'vs-input bg-toggle-enable']"
                >
									<option :value="''">Seleccione...</option>
									<option :value="tipo.Id" v-for="tipo in usosInmuebles" :key="tipo.Id">{{ tipo.Nombre }}</option>
								</select>
							</div>
							<div class="col-md-4">
								<span class="span-placeholder" for="tipoInmueble">Tipo de inmueble</span>
								<select 
                  v-bind:disabled="isDatoAdicionalEdit" 
                  name="cars" id="tipoInmueble" 
                  v-model="adicionales.tipoInmueble" 
                  v-bind:class="[ isDatoAdicionalEdit ? 'vs-input bg-toggle-disable' : 'vs-input bg-toggle-enable']"
                >
									<option :value="''">Seleccione...</option>
									<option :value="tipo.Id" v-for="tipo in tiposInmuebles" :key="tipo.Id">{{ tipo.Nombre }}</option>
								</select>
							</div>
						</div> -->
						<!-- <div class="row w-full">
							<div class="col-md-4">
								<span class="span-placeholder" for="estados">Identificación que presenta</span>
								<select 
                  v-bind:disabled="isDatoAdicionalEdit" 
                  name="cars" 
                  id="estados" 
                  v-model="adicionales.identificacionPresenta" 
                  v-bind:class="[ isDatoAdicionalEdit ? 'vs-input bg-toggle-disable' : 'vs-input bg-toggle-enable']"
                >
									<option :value="''">Seleccione...</option>
									<option :value="tipo.Id" v-for="tipo in identificaciones" :key="tipo.Id">{{ tipo.Nombre }}</option>
								</select>
							</div>
							<div class="col-md-4">
								<span class="span-placeholder">Número de identificación</span>
								<input 
                  v-bind:disabled="isDatoAdicionalEdit" 
                  v-bind:class="[ isDatoAdicionalEdit ? 'vs-input bg-toggle-disable' : 'vs-input bg-toggle-enable']" 
                  label="Número de identificación" 
                  v-model="adicionales.numeroIfe" name="name" />
							</div>
							<div class="col-md-4">
								<span class="span-placeholder">Vigencia Identificación</span>
								<input 
                  v-bind:disabled="isDatoAdicionalEdit" 
                  v-bind:class="[ isDatoAdicionalEdit ? 'vs-input bg-toggle-disable' : 'vs-input bg-toggle-enable']" 
                  label="Vigencia Identificación" 
                  type="date" v-model="adicionales.vigencia" name="fechaNacimiento" />
							</div>
						</div> -->
						<!-- <div class="row w-full">
							<div class="col-md-4">
								<span class="span-placeholder">Número de seguro social</span>
								<input 
                  v-bind:disabled="isDatoAdicionalEdit" 
                  v-bind:class="[ isDatoAdicionalEdit ? 'vs-input bg-toggle-disable' : 'vs-input bg-toggle-enable']" 
                  v-validate="'required|min:11|max:11'" 
                  label="Número de seguro social" v-model="adicionales.nss" name="nss" v-on:keypress="isNumber($event)" />
								<span class="text-danger span-placeholder" v-show="errors.has('nss')">El campo debe tener 11 caracteres</span>
							</div>
							<div class="col-md-4">
								<span class="span-placeholder">Número de ISSSTE</span>
								<input 
                  v-bind:disabled="isDatoAdicionalEdit" 
                  v-bind:class="[ isDatoAdicionalEdit ? 'vs-input bg-toggle-disable' : 'vs-input bg-toggle-enable']" 
                  label="Número de ISSSTE" v-model="adicionales.issste" name="name" />
							</div>
							<div class="col-md-4">
								<vx-tooltip text="Requerido por: HSBC, INBURSA">
									<span class="span-placeholder">FIEL</span>
									<input 
                    v-bind:disabled="isDatoAdicionalEdit" 
                    v-bind:class="[ isDatoAdicionalEdit ? 'vs-input bg-toggle-disable' : 'vs-input bg-toggle-enable']" 
                    label="FIEL" 
                    v-model="adicionales.fiel" name="name" />
								</vx-tooltip>
							</div>
						</div> -->
						<!-- <div class="row w-full"> -->
							<!-- <div class="col-md-4">
								<span class="span-placeholder" for="formasEnganche">Forma de Enganche</span>
								<select 
                  v-bind:disabled="isDatoAdicionalEdit" 
                  name="cars" 
                  id="formasEnganche" 
                  v-model="adicionales.formaEnganche" 
                  v-bind:class="[ isDatoAdicionalEdit ? 'vs-input bg-toggle-disable' : 'vs-input bg-toggle-enable']"
                >
									<option :value="''">Seleccione...</option>
									<option :value="tipo.Id" v-for="tipo in formasEnganche" :key="tipo.Id">{{ tipo.Nombre }}</option>
								</select>
							</div> -->
							<!-- <div class="col-md-4">
								<span class="span-placeholder" for="">¿Seguros con el banco?</span>
								<vs-switch v-bind:disabled="isDatoAdicionalEdit" v-model="adicionales.seguros" />
							</div> -->

							<!-- <div class="col-md-4">
								<span class="span-placeholder">Tiempo ocupando el domicilio actual</span> -->
								<!-- <input 
                  v-bind:disabled="isDatoAdicionalEdit" 
                  v-bind:class="[ isDatoAdicionalEdit ? 'vs-input bg-toggle-disable' : 'vs-input bg-toggle-enable']" 
                  label="Tiempo ocupando el domicilio actual" 
                  v-model="adicionales.tiempoOcupandoDomicilioActual" name="name" /> -->

                <!-- <div class="flex">
                    
                    <span class="col-4">
                        <input 
                            v-bind:disabled="isDatoAdicionalEdit"
                            v-bind:class="[ isDatoAdicionalEdit ? 'vs-input bg-toggle-disable' : 'vs-input bg-toggle-enable']"
                            class="input-antiguedad-am"
                            label="Tiempo ocupando el domicilio actual"
                            v-model="adicionales.tiempoOcupandoDomicilioActual"
                            name="name"/>
                    </span>
                    <span 
                        class="col-2 input-antiguedad-fecha">
                        <span>A</span>
                    </span>

                    <span class="col-4">
                        <input 
                            v-bind:disabled="isDatoAdicionalEdit"
                            v-bind:class="[ isDatoAdicionalEdit ? 'vs-input bg-toggle-disable' : 'vs-input bg-toggle-enable']"
                            class="input-antiguedad-am"
                            label="Tiempo ocupando el domicilio actual"
                            v-model="adicionales.tiempoOcupandoDomicilioActualMeses"
                            name="name"/>
                    </span>
                    <span 
                        class="col-2 input-antiguedad-fecha">
                        <span>M</span>
                    </span>

                </div>

							</div> -->

						<!-- </div> -->
						<!-- <div class="row w-full">
							<div class="col-md-4">
								<span class="span-placeholder">Antigüedad en la ciudad</span> -->
								<!-- <input 
                  v-bind:disabled="isDatoAdicionalEdit" 
                  v-bind:class="[ isDatoAdicionalEdit ? 'vs-input bg-toggle-disable' : 'vs-input bg-toggle-enable']" 
                  label="Antigüedad en la ciudad en años" 
                  v-model="adicionales.antiguedadCiudad" name="username" v-on:keypress="isNumber($event)" /> -->

                <!-- <div class="flex">
                    
                    <span class="col-4">
                        <input 
                            v-bind:disabled="isDatoAdicionalEdit"
                            v-bind:class="[ isDatoAdicionalEdit ? 'vs-input bg-toggle-disable' : 'vs-input bg-toggle-enable']"
                            class="input-antiguedad-am"
                            label="Antigüedad en la ciudad"
                            v-model="adicionales.antiguedadCiudad"
                            v-on:keypress="isNumber($event)"
                            name="username"/>
                    </span>
                    <span 
                        class="col-2 input-antiguedad-fecha">
                        <span>A</span>
                    </span>

                    <span class="col-4">
                        <input 
                            v-bind:disabled="isDatoAdicionalEdit"
                            v-bind:class="[ isDatoAdicionalEdit ? 'vs-input bg-toggle-disable' : 'vs-input bg-toggle-enable']"
                            class="input-antiguedad-am"
                            label="Antigüedad en la ciudad"
                            v-model="adicionales.antiguedadCiudadMeses"
                            v-on:keypress="isNumber($event)"
                            name="username"/>
                    </span>
                    <span 
                        class="col-2 input-antiguedad-fecha">
                        <span>M</span>
                    </span>

                </div>

							</div> -->
							<!-- <div class="col-md-4">
								<span class="span-placeholder">Antigüedad del inmueble a adquirir en años</span>
								<input 
                  v-bind:disabled="isDatoAdicionalEdit" 
                  v-bind:class="[ isDatoAdicionalEdit ? 'vs-input bg-toggle-disable' : 'vs-input bg-toggle-enable']" 
                  label="Antigüedad del inmueble a adquirir en años" 
                  v-model="adicionales.antiguedadInmuebleAdquirir" name="name" v-on:keypress="isNumber($event)" />
							</div>
							<div class="col-md-4">
								<span class="span-placeholder">Antigüedad Domicilio Anterior en años</span>
								<input 
                  v-bind:disabled="isDatoAdicionalEdit" 
                  v-bind:class="[ isDatoAdicionalEdit ? 'vs-input bg-toggle-disable' : 'vs-input bg-toggle-enable']" 
                  label="Antigüedad Domicilio Anterior en años" 
                  v-model="adicionales.antiguedadDomicilioAnterior" name="name" v-on:keypress="isNumber($event)" />
							</div> -->
						<!-- </div> -->
						<!-- <div class="row w-full"> -->
							<!-- <div class="col-md-4">
								<span class="span-placeholder" for="grados">Máximo grado de estudios</span>
								<select 
                  v-bind:disabled="isDatoAdicionalEdit" 
                  name="cars" 
                  id="grados" 
                  v-model="adicionales.gradoEstudios" 
                  v-bind:class="[ isDatoAdicionalEdit ? 'vs-input bg-toggle-disable' : 'vs-input bg-toggle-enable']"
                >
									<option :value="''">Seleccione...</option>
									<option :value="tipo.Id" v-for="tipo in gradosEstudio" :key="tipo.id">{{ tipo.Nombre }}</option>
								</select>
							</div> -->
							<!-- <div class="col-md-4">
								<span class="span-placeholder">Tipo de institución de estudios</span>
								<input 
                  v-bind:disabled="isDatoAdicionalEdit" 
                  v-bind:class="[ isDatoAdicionalEdit ? 'vs-input bg-toggle-disable' : 'vs-input bg-toggle-enable']" 
                  label="Tipo de institución de estudios" 
                  v-model="adicionales.institucionEstudios" name="name" placeholder="Pública o Privada" />
							</div> -->
							<!-- <div class="col-md-4">
								<span class="span-placeholder">Monto de pensión alimenticia $ (Sí aplica)</span>
								<input 
                  v-bind:disabled="isDatoAdicionalEdit" 
                  v-bind:class="[ isDatoAdicionalEdit ? 'vs-input bg-toggle-disable' : 'vs-input bg-toggle-enable']" 
                  label="Monto de pensión alimenticia $ (Sí aplica)" 
                  v-model="adicionales.montoPension" name="name" />
							</div> -->
						<!-- </div> -->
						<!-- <div class="row w-full">
							<div class="col-md-4">
								<span class="span-placeholder">En caso de haber tenido otro nombre favor de indicarlo</span>
								<input 
                  v-bind:disabled="isDatoAdicionalEdit" 
                  v-bind:class="[ isDatoAdicionalEdit ? 'vs-input bg-toggle-disable' : 'vs-input bg-toggle-enable']" 
                  label="En caso de haber tenido otro nombre favor de indicarlo" 
                  v-model="adicionales.otroNombre" name="name" />
							</div> -->

							<!-- <div class="col-md-4">
								<span class="span-placeholder">Teléfono fijo (10 dígitos)</span>
								<input 
                  v-bind:disabled="isDatoAdicionalEdit" 
                  v-bind:class="[ isDatoAdicionalEdit ? 'vs-input bg-toggle-disable' : 'vs-input bg-toggle-enable']" 
                  label="Teléfono fijo (10 dígitos)" 
                  v-model="adicionales.telefonoFijo" name="name" />
							</div> -->

							<!-- <div class="col-md-4">
								<span class="span-placeholder">Compañia celular</span> -->
								<!-- <input class="vs-input" label="Compañia celular" v-model="adicionales.companiaCelular" name="name" /> -->
								<!-- <select 
                  v-bind:disabled="isDatoAdicionalEdit" 
                  name="cars" 
                  v-model="adicionales.companiaCelular" 
                  v-bind:class="[ isDatoAdicionalEdit ? 'vs-input bg-toggle-disable' : 'vs-input bg-toggle-enable']"
                >
									<option :value="''">Seleccione...</option>
									<option :value="compania.name" v-for="compania in companiasCelular" :key="compania.name">{{ compania.name }}</option>
								</select>
							</div> -->

							<!-- <div class="col-md-4">
								<span class="span-placeholder" for="estados">Estado de nacimiento</span>
								<select name="cars" id="estados" v-model="adicionales.estadoNacimiento" class="vs-input">
									<option :value="''">Seleccione...</option>
									<option :value="tipo.Id" v-for="tipo in estados" :key="tipo.Id">{{ tipo.Nombre }}</option>
								</select>
							</div> -->
							<!-- <div class="col-md-4">
								<span class="span-placeholder">Municipio de nacimiento</span>
								<input class="vs-input" label="Municipio de nacimiento" v-model="adicionales.municipioNacimiento" name="name" />
							</div> -->
						<!-- </div> -->
						<!-- <div class="row w-full"> -->
							<!-- <div class="col-md-4">
								<span class="span-placeholder">País de nacimiento</span>
								<input class="vs-input" label="País de nacimiento" v-model="adicionales.paisNacimiento" name="name" />
							</div> -->
							<!-- <div class="col-md-4">
								<span class="span-placeholder">Teléfono fijo (10 dígitos)</span>
								<input class="vs-input" label="Teléfono fijo (10 dígitos)" v-model="adicionales.telefonoFijo" name="name" />
							</div> -->
							<!-- <div class="col-md-4">
								<span class="span-placeholder">País de residencia</span>
								<input class="vs-input" label="País de residencia" v-model="adicionales.PaisRecidencia" name="name" />
							</div> -->
							<!-- <div class="col-md-4" hidden>
                  <span class="span-placeholder">Teléfono oficina (10 dígitos)</span>
                  <input class="vs-input" label="Teléfono oficina (10 dígitos)" v-model="adicionales.telefonoOficina" name="name" />
                </div>-->
						<!-- </div> -->
						<!-- <div class="row w-full"> -->
							<!-- <div class="col-md-4">
								<span class="span-placeholder">Compañia celular</span> -->
								<!-- <input class="vs-input" label="Compañia celular" v-model="adicionales.companiaCelular" name="name" /> -->
								<!-- <select name="cars" v-model="adicionales.companiaCelular" class="vs-input">
									<option :value="''">Seleccione...</option>
									<option :value="compania.name" v-for="compania in companiasCelular" :key="compania.name">{{ compania.name }}</option>
								</select>
							</div> -->
							<!-- <div class="col-md-4">
								<span class="span-placeholder" for="domicilioOcupaciones">Titularidad de domicilio actual</span>
								<select 
                  v-bind:disabled="isDatoAdicionalEdit" 
                  name="cars" id="domicilioOcupaciones" 
                  v-model="adicionales.titularidadDomicilio" 
                  v-bind:class="[ isDatoAdicionalEdit ? 'vs-input bg-toggle-disable' : 'vs-input bg-toggle-enable']"
                >
									<option :value="''">Seleccione...</option>
									<option :value="tipo.Id" v-for="tipo in domicilioOcupaciones" :key="tipo.id">{{ tipo.Nombre }}</option>
								</select>
							</div> -->
							<!-- <div class="col-md-4">
								<span class="span-placeholder">Total de gastos mensuales</span>
								<input 
                  v-bind:disabled="isDatoAdicionalEdit" 
                  v-bind:class="[ isDatoAdicionalEdit ? 'vs-input bg-toggle-disable' : 'vs-input bg-toggle-enable']" 
                  label="Total de gastos mensuales" v-model="adicionales.totalGastosMensuales" name="name" v-on:keypress="isNumber($event)" />
							</div> -->
						<!-- </div> -->

						<!-- <h4>Datos ciudadano extranjero</h4> -->
						<!-- <div class="row w-full"> -->
							<!-- <div class="col-md-4">
								<span class="span-placeholder">Calle</span>
								<input 
                  v-bind:disabled="isDatoAdicionalEdit" 
                  v-bind:class="[ isDatoAdicionalEdit ? 'vs-input bg-toggle-disable' : 'vs-input bg-toggle-enable']" 
                  label="Calle" 
                  v-model="adicionales.calleExtranjero" name="name" />
							</div> -->
							<!-- <div class="col-md-4">
								<span class="span-placeholder">Número exterior</span>
								<input 
                  v-bind:disabled="isDatoAdicionalEdit" 
                  v-bind:class="[ isDatoAdicionalEdit ? 'vs-input bg-toggle-disable' : 'vs-input bg-toggle-enable']" 
                  label="Número exterior" 
                  v-model="adicionales.numeroExteriorExtranjero" name="name" />
							</div> -->
							<!-- <div class="col-md-4">
								<span class="span-placeholder">Número interior</span>
								<input 
                  v-bind:disabled="isDatoAdicionalEdit" 
                  v-bind:class="[ isDatoAdicionalEdit ? 'vs-input bg-toggle-disable' : 'vs-input bg-toggle-enable']" 
                  label="Número interior" 
                  v-model="adicionales.numeroInteriorExtranjero" name="name" />
							</div> -->
							<!-- <div class="col-md-4">
								<span class="span-placeholder">Colonia</span>
								<input 
                  v-bind:disabled="isDatoAdicionalEdit" 
                  v-bind:class="[ isDatoAdicionalEdit ? 'vs-input bg-toggle-disable' : 'vs-input bg-toggle-enable']" 
                  label="Colonia" 
                  v-model="adicionales.coloniaExtranjero" name="name" />
							</div> -->
						<!-- </div> -->
						<!-- <div class="row w-full"> -->
							<!-- <div class="col-md-4">
								<span class="span-placeholder">Estado</span>
								<input 
                  v-bind:disabled="isDatoAdicionalEdit" 
                  v-bind:class="[ isDatoAdicionalEdit ? 'vs-input bg-toggle-disable' : 'vs-input bg-toggle-enable']" 
                  label="Estado" 
                  v-model="adicionales.estadoExtranjero" name="name" />
							</div> -->
							<!-- <div class="col-md-4">
								<span class="span-placeholder">País</span>
                <select 
                  v-bind:disabled="isDatoAdicionalEdit"
                  name="name" 
                  v-model="adicionales.paisExtranjero" 
                  v-bind:class="[ isDatoAdicionalEdit ? 'vs-input bg-toggle-disable' : 'vs-input bg-toggle-enable']"
                >
                  <option :value='pais.Nombre' v-for="pais in paisesNac" :key="">{{ pais.Nombre }}</option>
                </select> -->

								<!-- <input 
                  v-bind:disabled="isDatoAdicionalEdit" 
                  v-bind:class="[ isDatoAdicionalEdit ? 'vs-input bg-toggle-disable' : 'vs-input bg-toggle-enable']" 
                  label="País" 
                  v-model="adicionales.paisExtranjero" name="name" /> -->
                
							<!-- </div> -->
							<!-- <div class="col-md-4">
								<span class="span-placeholder">Código postal</span>
								<input 
                  v-bind:disabled="isDatoAdicionalEdit" 
                  v-bind:class="[ isDatoAdicionalEdit ? 'vs-input bg-toggle-disable' : 'vs-input bg-toggle-enable']" 
                  label="Código postal" 
                  v-model="adicionales.codigoPostalExtranjero" name="name" />
							</div> -->

              <!-- <div class="col-md-4"> Se retira por req 2024-01-31
                <span class="span-placeholder">Nacionalidad</span>
                <select 
                  v-bind:disabled="isDatoAdicionalEdit" 
                  name="cars" 
                  v-model="adicionales.NacionalidadDetalle" 
                  v-bind:class="[ isDatoAdicionalEdit ? 'vs-input bg-toggle-disable' : 'vs-input bg-toggle-enable']"
                >
                  <option :value="''">Seleccione...</option>
                  <option :value="tipo.Nombre" v-for="tipo in nacionalidades">{{ tipo.Nombre }}</option>
                </select>
              </div> -->

							<!-- <div class="col-md-4">
								<span class="span-placeholder">Nacionalidad</span>
								<input 
                  v-bind:disabled="isDatoAdicionalEdit" 
                  v-bind:class="[ isDatoAdicionalEdit ? 'vs-input bg-toggle-disable' : 'vs-input bg-toggle-enable']" 
                  label="Nacionalidad" 
                  v-model="adicionales.NacionalidadDetalle" name="name" />
							</div> -->


						<!-- </div> -->
					<!-- </div>
				</div>
			</div> Seccion Personales Fin -->
			<div class="accordion-item">
				<h2 class="accordion-header" id="headingThree-dat">
					<button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseThree-asesor" aria-expanded="false" aria-controls="collapseThree-asesor">Asesor SOC</button>
				</h2>
				<div id="collapseThree-asesor" class="accordion-collapse collapse" aria-labelledby="headingThree-dat" data-bs-parent="#accordionExample4">
					<div class="accordion-body accordion-scrolling mh-4">
						<div class="row w-full">
							<div class="col-md-4">
								<span class="span-placeholder">Nombre</span>
								<input 
                  v-bind:disabled="isDatoAdicionalEdit" 
                  v-bind:class="[ isDatoAdicionalEdit ? 'vs-input bg-toggle-disable' : 'vs-input bg-toggle-enable']" 
                  label="Nombre" v-model="adicionales.nombreAsesor" v-on:keypress="soloLetras($event)" name="name" />
							</div>
							<div class="col-md-4">
								<span class="span-placeholder">RFC</span>
								<input 
                  v-bind:disabled="isDatoAdicionalEdit" 
                  v-bind:class="[ isDatoAdicionalEdit ? 'vs-input bg-toggle-disable' : 'vs-input bg-toggle-enable']" 
                  label="RFC" 
                  v-model="adicionales.rfcAsesor" name="name" v-validate="'max:13'" v-mask="'AAAA######NNN'" onkeyup="javascript:this.value=this.value.toUpperCase();" />
								<span class="text-danger span-placeholder" v-show="errors.has('rfcAsesor')">El campo debe tener 13 caracteres</span>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div class="accordion-item">
				<h2 class="accordion-header" id="headingTwo-dat">
					<button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwo-dat" aria-expanded="false" aria-controls="collapseTwo-dat">Seguros</button>
				</h2>

				<div id="collapseTwo-dat" class="accordion-collapse collapse" aria-labelledby="headingTwo-dat" data-bs-parent="#accordionExample4">
					<div class="accordion-body accordion-scrolling mh-4">
						<div class="w-full">
							<div class="row">
								<div class="col-md-4 alinear">
									<div class="span-placeholder" style="padding: 0.7em !important; margin-top: 10px">Altura</div>
									<vs-input 
                    v-bind:disabled="isDatoAdicionalEdit" 
                    v-bind:class="[ isDatoAdicionalEdit ? 'small bg-toggle-disable' : 'small bg-toggle-enable']" 
                    v-model="estatura" 
                    name="estatura" style="padding: 0.4em !important" v-on:keyup="validaIngersoMensual()" v-on:keypress="isNumber($event)" />
									<span class="text-danger span-placeholder" v-show="errors.has('altura')">Mínimo son 6 digitos</span>
									<div class="span-placeholder" style="padding: 0.7em !important; margin-top: 10px">m</div>
								</div>

								<div class="col-md-4 alinear">
									<div class="span-placeholder" style="padding: 0.7em; margin-top: 10px">Peso</div>
									<vs-input 
                    v-bind:disabled="isDatoAdicionalEdit"  
                    v-bind:class="[ isDatoAdicionalEdit ? 'small bg-toggle-disable' : 'small bg-toggle-enable']" 
                    v-model="peso" 
                    name="peso" 
                    maxlength="6" 
                    v-on:keyup="validaIngersoMensual()" v-on:keypress="isNumber($event)" />
									<div class="span-placeholder" style="padding: 0.7em; margin-top: 10px">Kg</div>
								</div>
								<div class="col-md-4"></div>
							</div>
							<br />
							<br />
							<hr />
						</div>
						<div class="row w-full">
							<div class="col-md-10">
								<h6>Afecciones médicas</h6>
							</div>
							<div class="col-md-2">
								<vs-switch v-bind:disabled="isDatoAdicionalEdit" v-model="SolswitchAfecMedicas" @click="cambia(1)" :label="`Afecciones medicas: ${SolswitchAfecMedicas.toString()}`" />
							</div>
							<br /><br />
							<hr />
							<div class="col-md-12" style="display: none" id="Afecciones">
								<br />
								<div class="row">
									<div class="col-md-6 fuente_check" v-for="(padecimiento, index) in padecimientosOpcionesAfecciones" :key="index" vs-type="flex" vs-justify="left" vs-align="left" vs-lg="4" vs-sm="12" vs-xs="12">
										<vs-checkbox v-bind:disabled="isDatoAdicionalEdit" v-model="padecimientos" :vs-value="padecimiento.Id">{{ padecimiento.Nombre }}</vs-checkbox>
										<br />
									</div>
								</div>
							</div>
						</div>

						<div class="row w-full">
							<div class="col-md-10">
								<h6>Procedimientos médicos</h6>
							</div>
							<div class="col-md-2">
								<vs-switch v-bind:disabled="isDatoAdicionalEdit" v-model="SolswitchProMedicos" @click="cambia(2)" />
							</div>
							<br /><br />
							<hr />
							<div class="col-md-12" style="display: none" id="Procedimientos">
								<br />
								<div class="row">
									<div class="col-md-6 fuente_check" v-for="(padecimiento, index) in padecimientosOpcionesProcedimientos" :key="index" vs-type="flex" vs-justify="left" vs-align="left" vs-lg="4" vs-sm="12" vs-xs="12">
										<vs-checkbox v-bind:disabled="isDatoAdicionalEdit" v-model="padecimientos" :vs-value="padecimiento.Id">{{ padecimiento.Nombre }}</vs-checkbox>
										<br />
									</div>
								</div>
							</div>
						</div>

						<div class="row w-full">
							<div class="col-md-10">
								<h6>Historial médico</h6>
							</div>
							<div class="col-md-2">
								<vs-switch v-bind:disabled="isDatoAdicionalEdit" v-model="SolswitchHistorial" @click="cambia(3)" />
							</div>
							<br /><br />
							<hr />
							<div class="col-md-12" style="display: none" id="Historial">
								<br />
								<div class="row">
									<div class="col-md-6 fuente_check" v-for="(padecimiento, index) in padecimientosOpcionesHistorial" :key="index" vs-type="flex" vs-justify="left" vs-align="left" vs-lg="4" vs-sm="12" vs-xs="12">
										<vs-checkbox v-bind:disabled="isDatoAdicionalEdit" v-model="padecimientos" :vs-value="padecimiento.Id">{{ padecimiento.Nombre }}</vs-checkbox>
										<br />
									</div>
								</div>
							</div>
						</div>

						<div class="row w-full">
							<div class="col-md-10">
								<h6>Hábitos</h6>
							</div>
							<div class="col-md-2">
								<vs-switch v-bind:disabled="isDatoAdicionalEdit" v-model="SolswitchHabitos" @click="cambia(4)" />
							</div>
							<br /><br />
							<hr />
							<div class="col-md-12" style="display: none" id="Habitos">
								<br />
								<div class="row">
									<div class="col-md-6 fuente_check" v-for="(padecimiento, index) in padecimientosOpcionesHabitos" :key="index" vs-type="flex" vs-justify="left" vs-align="left" vs-lg="4" vs-sm="12" vs-xs="12">
										<vs-checkbox v-bind:disabled="isDatoAdicionalEdit" v-model="padecimientos" :vs-value="padecimiento.Id">{{ padecimiento.Nombre }}</vs-checkbox>
										<br />
									</div>
								</div>
							</div>
						</div>

						<div class="row w-full">
							<div class="col-md-10">
								<h6>Actividad laboral</h6>
							</div>
							<div class="col-md-2">
								<vs-switch v-bind:disabled="isDatoAdicionalEdit" v-model="SolswitchAcLaboral" @click="cambia(5)" />
							</div>
							<br /><br />
							<hr />
							<div class="col-md-12" style="display: none" id="AcLaboral">
								<br />
								<div class="row">
									<div class="col-md-6 fuente_check" v-for="(padecimiento, index) in padecimientosOpcionesAcLaboral" :key="index" vs-type="flex" vs-justify="left" vs-align="left" vs-lg="4" vs-sm="12" vs-xs="12">
										<vs-checkbox v-bind:disabled="isDatoAdicionalEdit" v-model="padecimientos" :vs-value="padecimiento.Id">{{ padecimiento.Nombre }}</vs-checkbox>
										<br />
									</div>
								</div>
							</div>
						</div>

						<div class="row w-full">
							<div class="col-md-10">
								<h6>Otros</h6>
							</div>
							<div class="col-md-2">
								<vs-switch v-bind:disabled="isDatoAdicionalEdit" v-model="SolswitchOtro" @click="cambia(6)" />
							</div>
							<br /><br />
							<hr />
							<div class="col-md-12" style="display: none" id="Otro">
								<br />
								<div class="row">
									<div class="col-md-6 fuente_check" v-for="(padecimiento, index) in padecimientosOpcionesOtro" :key="index" vs-type="flex" vs-justify="left" vs-align="left" vs-lg="4" vs-sm="12" vs-xs="12">
										<vs-checkbox v-bind:disabled="isDatoAdicionalEdit" v-model="padecimientos" :vs-value="padecimiento.Id">{{ padecimiento.Nombre }}</vs-checkbox>
										<br />
									</div>
								</div>
							</div>
						</div>

						<div class="row w-full">
							<div class="col-md-10">
								<h6>CITIBANAMEX</h6>
							</div>
							<div class="col-md-2">
								<vs-switch v-bind:disabled="isDatoAdicionalEdit" v-model="SolswitchCiti" @click="cambia(7)" />
							</div>
							<br /><br />
							<hr />
							<div class="col-md-12" style="display: none" id="Citi">
								<br />

								<div class="row w-full">
									<div class="col-md-5">Seguro de vida</div>
									<div class="col-md-2"></div>
									<div class="col-md-5">Seguro de daños</div>
								</div>
								<div class="row w-full">
									<div class="col-md-5"><vs-checkbox v-bind:disabled="isDatoAdicionalEdit" v-model="SeguroInsolutoCiti">Saldo insoluto del crédito</vs-checkbox></div>
									<div class="col-md-2"></div>
									<div class="col-md-5"><vs-checkbox v-bind:disabled="isDatoAdicionalEdit" v-model="SeguroDanosCiti">Con la aseguradora proporcionada por citibanamex y que se reflejará en la carátula</vs-checkbox></div>
								</div>

								<div class="row w-full">
									<div class="col-md-5"><vs-checkbox v-bind:disabled="isDatoAdicionalEdit" v-model="SeguroMontoCiti">Monto original del crédito</vs-checkbox></div>
								</div>
							</div>
						</div>

						<!--	<div class="row w-full" v-if="isActiveContentCiti">
							<h6 class="mb-4">REFERENCIA APLICADA ÚNICAMENTE PARA EL BANCO CITIBANAMEX.</h6>
							<div class="col-md-8 col-lg-6 text-center mb-4">
								<span class="span-placeholder" for="tipoSeguro">Seguro de vida</span>
								<div class="row">
									<div class="col-md-6 content-radio">
										<vs-radio v-model="tipoSeguro" vs-name="tipoSeguro" vs-value="Insoluto"></vs-radio>
										<span class="span-placeholder" for="Insoluto">Saldo Insoluto</span>
									</div>
									<div class="col-md-6 content-radio">
										<vs-radio v-model="tipoSeguro" vs-name="tipoSeguro" vs-value="Original"></vs-radio>
										<span class="span-placeholder" for="Original">Monto Original</span>
									</div>
								</div>
							</div>
							<div class="col-md-4">
								<span class="span-placeholder" for="">Incluir seguro por daños</span>
								<vs-switch v-model="seguroDanos" />
							</div>
						</div>-->
					</div>
				</div>
			</div>
			<div class="accordion-item">
				<h2 class="accordion-header" id="headingEight-dat">
					<button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseEight-dat" aria-expanded="false" aria-controls="collapseEight-dat">Inmueble en garantía</button>
				</h2>
				<div id="collapseEight-dat" class="accordion-collapse collapse" aria-labelledby="headingEight-dat" data-bs-parent="#accordionExample4">
					<div class="accordion-body accordion-scrolling mh-4">
						<div class="row w-full">
							<div class="col-md-3">
								<span class="span-placeholder">Calle</span>
								<input 
                  v-bind:disabled="isDatoAdicionalEdit" 
                  v-bind:class="[ isDatoAdicionalEdit ? 'vs-input bg-toggle-disable' : 'vs-input bg-toggle-enable']" 
                  label="Calle" v-model="adicionales.inmuebleGarantia.calle" name="name" />
							</div>
							<div class="col-md-3">
								<span class="span-placeholder">Número</span>
								<input 
                  v-bind:disabled="isDatoAdicionalEdit" 
                  v-bind:class="[ isDatoAdicionalEdit ? 'vs-input bg-toggle-disable' : 'vs-input bg-toggle-enable']" 
                  label="Número" v-model="adicionales.inmuebleGarantia.numero" name="name" />
							</div>
							<div class="col-md-3">
								<span class="span-placeholder">Colonia</span>
								<input 
                  v-bind:disabled="isDatoAdicionalEdit" 
                  v-bind:class="[ isDatoAdicionalEdit ? 'vs-input bg-toggle-disable' : 'vs-input bg-toggle-enable']" 
                  label="Colonia" 
                  v-model="adicionales.inmuebleGarantia.colonia" name="name" />
							</div>
							<div class="col-md-3">
								<span class="span-placeholder">Municipio</span>
								<input 
                  v-bind:disabled="isDatoAdicionalEdit" 
                  v-bind:class="[ isDatoAdicionalEdit ? 'vs-input bg-toggle-disable' : 'vs-input bg-toggle-enable']" 
                  label="Municipio" 
                  v-model="adicionales.inmuebleGarantia.municipio" name="name" />
							</div>
						</div>
						<div class="row w-full">
							<div class="col-md-3">
								<span class="span-placeholder">Ciudad</span>
								<input 
                  v-bind:disabled="isDatoAdicionalEdit" 
                  v-bind:class="[ isDatoAdicionalEdit ? 'vs-input bg-toggle-disable' : 'vs-input bg-toggle-enable']" 
                  label="Ciudad" 
                  v-model="adicionales.inmuebleGarantia.ciudad" name="name" />
							</div>
							<div class="col-md-3">
								<span class="span-placeholder" for="estados">Estado</span>
								<select 
                  v-bind:disabled="isDatoAdicionalEdit" 
                  name="cars" 
                  id="estados" 
                  v-model="adicionales.inmuebleGarantia.estado" 
                  v-bind:class="[ isDatoAdicionalEdit ? 'vs-input bg-toggle-disable' : 'vs-input bg-toggle-enable']"
                >
									<option :value="''">Seleccione...</option>
									<option :value="tipo.Id" v-for="tipo in estados" :key="tipo.Id">{{ tipo.Nombre }}</option>
								</select>
							</div>
							<div class="col-md-3">
								<span class="span-placeholder">País</span>

                <select 
                  v-bind:disabled="isDatoAdicionalEdit"
                  name="name" 
                  v-model="adicionales.inmuebleGarantia.pais" 
                  v-bind:class="[ isDatoAdicionalEdit ? 'vs-input bg-toggle-disable' : 'vs-input bg-toggle-enable']"
                >
                  <option :value='pais.Nombre' v-for="pais in paisesNac" :key="">{{ pais.Nombre }}</option>
                </select>

								<!-- <input 
                  v-bind:disabled="isDatoAdicionalEdit" 
                  v-bind:class="[ isDatoAdicionalEdit ? 'vs-input bg-toggle-disable' : 'vs-input bg-toggle-enable']" 
                  label="País" 
                  v-model="adicionales.inmuebleGarantia.pais" name="name" /> -->
							</div>
							<div class="col-md-3">
								<span class="span-placeholder">Código postal</span>
								<input 
                  v-bind:disabled="isDatoAdicionalEdit" 
                  v-bind:class="[ isDatoAdicionalEdit ? 'vs-input bg-toggle-disable' : 'vs-input bg-toggle-enable']" 
                  label="Código Postal" 
                  v-model="adicionales.inmuebleGarantia.codigo" name="name" />
							</div>
						</div>
						<div class="row w-full">
							<div class="col-md-3">
								<span class="span-placeholder">Lote</span>
								<input 
                  v-bind:disabled="isDatoAdicionalEdit" 
                  v-bind:class="[ isDatoAdicionalEdit ? 'vs-input bg-toggle-disable' : 'vs-input bg-toggle-enable']" 
                  label="Lote" 
                  v-model="adicionales.inmuebleGarantia.lote" 
                  name="name" />
							</div>
							<div class="col-md-3">
								<span class="span-placeholder">Manzana</span>
								<input 
                  v-bind:disabled="isDatoAdicionalEdit" 
                  v-bind:class="[ isDatoAdicionalEdit ? 'vs-input bg-toggle-disable' : 'vs-input bg-toggle-enable']" 
                  label="Manzana" 
                  v-model="adicionales.inmuebleGarantia.manzana" name="name" />
							</div>
							<div class="col-md-3">
								<span class="span-placeholder">Valor estimado</span>
								<input 
                  v-bind:disabled="isDatoAdicionalEdit" 
                  v-bind:class="[ isDatoAdicionalEdit ? 'vs-input bg-toggle-disable' : 'vs-input bg-toggle-enable']" 
                  label="Valor estimado" 
                  v-model="adicionales.inmuebleGarantia.valorEstimado" name="name" v-on:keypress="isNumber($event)" />
							</div>
							<div class="col-md-3">
								<span class="span-placeholder">Superficie Terreno m2</span>
								<input 
                  v-bind:disabled="isDatoAdicionalEdit" 
                  v-bind:class="[ isDatoAdicionalEdit ? 'vs-input bg-toggle-disable' : 'vs-input bg-toggle-enable']" 
                  label="Superficie Terreno m2" 
                  v-model="adicionales.inmuebleGarantia.superficieTerreno" name="name" v-on:keypress="isNumber($event)" />
							</div>
						</div>
						<div class="row w-full">
							<div class="col-md-3">
								<span class="span-placeholder">Área de construcción m2</span>
								<input 
                  v-bind:disabled="isDatoAdicionalEdit" 
                  v-bind:class="[ isDatoAdicionalEdit ? 'vs-input bg-toggle-disable' : 'vs-input bg-toggle-enable']" 
                  label="Área de construcción m2" v-model="adicionales.inmuebleGarantia.areaConstruccion" name="name" v-on:keypress="isNumber($event)" />
							</div>
							<div class="col-md-3">
								<span class="span-placeholder">Descripcion</span>
								<input 
                  v-bind:disabled="isDatoAdicionalEdit" 
                  v-bind:class="[ isDatoAdicionalEdit ? 'vs-input bg-toggle-disable' : 'vs-input bg-toggle-enable']" 
                  label="Descripcion" 
                  v-model="adicionales.inmuebleGarantia.descripcion" name="name" />
							</div>
						</div>
					</div>
				</div>
			</div>
			<div class="accordion-item">
				<h2 class="accordion-header" id="headingThree-dat">
					<button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseThree-dat" aria-expanded="false" aria-controls="collapseThree-dat">Datos Inmobiliaria</button>
				</h2>
				<div id="collapseThree-dat" class="accordion-collapse collapse" aria-labelledby="headingThree-dat" data-bs-parent="#accordionExample4">
					<div class="accordion-body accordion-scrolling mh-4">
						<div class="row w-full">
							<div class="col-md-4">
								<span class="span-placeholder">Nombre inmobiliaria</span>
								<input 
                  v-bind:disabled="isDatoAdicionalEdit" 
                  v-bind:class="[ isDatoAdicionalEdit ? 'vs-input bg-toggle-disable' : 'vs-input bg-toggle-enable']" 
                  label="Nombre inmobiliaria" 
                  v-model="adicionales.empresa.nombre" name="name" />
							</div>
							<div class="col-md-4">
								<span class="span-placeholder">RFC inmobiliaria</span>
								<input 
                  v-bind:disabled="isDatoAdicionalEdit" 
                  v-bind:class="[ isDatoAdicionalEdit ? 'vs-input bg-toggle-disable' : 'vs-input bg-toggle-enable']" 
                  label="RFC inmobiliaria" v-model="adicionales.empresa.rfc" name="name" />
							</div>
							<div class="col-md-4">
								<span class="span-placeholder">Nombre representante legal</span>
								<input 
                  v-bind:disabled="isDatoAdicionalEdit" 
                  v-bind:class="[ isDatoAdicionalEdit ? 'vs-input bg-toggle-disable' : 'vs-input bg-toggle-enable']" 
                  label="Nombre representante legal" 
                  v-model="adicionales.empresa.representanteLegal" name="name" />
							</div>
						</div>
						<div class="row w-full">
							<div class="col-md-4">
								<span class="span-placeholder">Apellido paterno representante legal</span>
								<input 
                  v-bind:disabled="isDatoAdicionalEdit" 
                  v-bind:class="[ isDatoAdicionalEdit ? 'vs-input bg-toggle-disable' : 'vs-input bg-toggle-enable']" 
                  label="Apellido paterno de representante legal" 
                  v-model="adicionales.empresa.apellidoPaternoRepresentante" name="name" />
							</div>
							<div class="col-md-4">
								<span class="span-placeholder">Apellido materno representante legal</span>
								<input 
                  v-bind:disabled="isDatoAdicionalEdit" 
                  v-bind:class="[ isDatoAdicionalEdit ? 'vs-input bg-toggle-disable' : 'vs-input bg-toggle-enable']" 
                  label="Apellido materno representante legal" 
                  v-model="adicionales.empresa.apellidoMaternoRepresentante" name="name" />
							</div>
							<div class="col-md-4">
								<span class="span-placeholder">Representante legal RFC</span>
								<input 
                  v-bind:disabled="isDatoAdicionalEdit" 
                  v-bind:class="[ isDatoAdicionalEdit ? 'vs-input bg-toggle-disable' : 'vs-input bg-toggle-enable']" 
                  label="Representante legal RFC" 
                  v-model="adicionales.empresa.representanteLegalRfc" name="name" />
							</div>
						</div>
					</div>
				</div>
			</div>
			<div class="accordion-item">
				<h2 class="accordion-header" id="headingFour-dat">
					<button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseFour-dat" aria-expanded="false" aria-controls="collapseFour-dat">Propietario del inmueble</button>
				</h2>
				<div id="collapseFour-dat" class="accordion-collapse collapse" aria-labelledby="headingFour-dat" data-bs-parent="#accordionExample4">
					<div class="accordion-body accordion-scrolling mh-4">
						<div class="row w-full">
							<div class="col-md-4">
								<span class="span-placeholder">Nombre</span>
								<input 
                  v-bind:disabled="isDatoAdicionalEdit" 
                  v-bind:class="[ isDatoAdicionalEdit ? 'vs-input bg-toggle-disable' : 'vs-input bg-toggle-enable']" 
                  label="Nombre" 
                  v-model="adicionales.propietarioInmueble.nombre" name="name" />
							</div>
							<div class="col-md-4">
								<span class="span-placeholder">Apellido Paterno</span>
								<input 
                  v-bind:disabled="isDatoAdicionalEdit" 
                  v-bind:class="[ isDatoAdicionalEdit ? 'vs-input bg-toggle-disable' : 'vs-input bg-toggle-enable']" 
                  label="Apellido Paterno" 
                  v-model="adicionales.propietarioInmueble.apellidoPaterno" name="name" />
							</div>
							<div class="col-md-4">
								<span class="span-placeholder">Apellido Materno</span>
								<input 
                  v-bind:disabled="isDatoAdicionalEdit" 
                  v-bind:class="[ isDatoAdicionalEdit ? 'vs-input bg-toggle-disable' : 'vs-input bg-toggle-enable']" 
                  label="Apellido Materno" 
                  v-model="adicionales.propietarioInmueble.apellidoMaterno" name="name" />
							</div>
						</div>
						<div class="row w-full">
							<div class="col-md-4">
								<span class="span-placeholder">Calle</span>
								<input 
                  v-bind:disabled="isDatoAdicionalEdit" 
                  v-bind:class="[ isDatoAdicionalEdit ? 'vs-input bg-toggle-disable' : 'vs-input bg-toggle-enable']" 
                  label="Calle" 
                  v-model="adicionales.propietarioInmueble.calle" name="name" />
							</div>
							<div class="col-md-4">
								<span class="span-placeholder">Número exterior</span>
								<input 
                  v-bind:disabled="isDatoAdicionalEdit" 
                  v-bind:class="[ isDatoAdicionalEdit ? 'vs-input bg-toggle-disable' : 'vs-input bg-toggle-enable']" 
                  label="Número exterior" 
                  v-model="adicionales.propietarioInmueble.numeroExterior" name="name" />
							</div>
							<div class="col-md-4">
								<span class="span-placeholder">Colonia</span>
								<input 
                  v-bind:disabled="isDatoAdicionalEdit" 
                  v-bind:class="[ isDatoAdicionalEdit ? 'vs-input bg-toggle-disable' : 'vs-input bg-toggle-enable']" 
                  label="Colonia" 
                  v-model="adicionales.propietarioInmueble.colonia" name="name" />
							</div>
						</div>
						<div class="row w-full">
							<div class="col-md-4">
								<span class="span-placeholder">Municipio</span>
								<input 
                  v-bind:disabled="isDatoAdicionalEdit" 
                  v-bind:class="[ isDatoAdicionalEdit ? 'vs-input bg-toggle-disable' : 'vs-input bg-toggle-enable']" 
                  label="Municipio" v-model="adicionales.propietarioInmueble.municipio" name="name" />
							</div>
							<div class="col-md-4">
								<span class="span-placeholder">Ciudad</span>
								<input 
                  v-bind:disabled="isDatoAdicionalEdit" 
                  v-bind:class="[ isDatoAdicionalEdit ? 'vs-input bg-toggle-disable' : 'vs-input bg-toggle-enable']" 
                  label="Ciudad" 
                  v-model="adicionales.propietarioInmueble.ciudad" name="name" />
							</div>
							<div class="col-md-4">
								<span class="span-placeholder" for="estados">Estado</span>
								<select 
                  v-bind:disabled="isDatoAdicionalEdit" 
                  name="cars" 
                  id="estados" 
                  v-model="adicionales.propietarioInmueble.estado" 
                  v-bind:class="[ isDatoAdicionalEdit ? 'vs-input bg-toggle-disable' : 'vs-input bg-toggle-enable']"
                >
									<option :value="''">Seleccione...</option>
									<option :value="tipo.Id" v-for="tipo in estados" :key="tipo.Id">{{ tipo.Nombre }}</option>
								</select>
							</div>
						</div>
						<div class="row w-full">
							<div class="col-md-4">
								<span class="span-placeholder">País</span>
                <select 
                  v-bind:disabled="isDatoAdicionalEdit"
                  name="name" 
                  v-model="adicionales.propietarioInmueble.pais" 
                  v-bind:class="[ isDatoAdicionalEdit ? 'vs-input bg-toggle-disable' : 'vs-input bg-toggle-enable']"
                >
                  <option :value='pais.Nombre' v-for="pais in paisesNac" :key="">{{ pais.Nombre }}</option>
                </select>

								<!-- <input 
                  v-bind:disabled="isDatoAdicionalEdit" 
                  v-bind:class="[ isDatoAdicionalEdit ? 'vs-input bg-toggle-disable' : 'vs-input bg-toggle-enable']" 
                  label="País" 
                  v-model="adicionales.propietarioInmueble.pais" name="name" /> -->
							</div>
							<div class="col-md-4">
								<span class="span-placeholder">Código postal</span>
								<input 
                  v-bind:disabled="isDatoAdicionalEdit" 
                  v-bind:class="[ isDatoAdicionalEdit ? 'vs-input bg-toggle-disable' : 'vs-input bg-toggle-enable']" 
                  label="Código postal" 
                  v-model="adicionales.propietarioInmueble.codigoPostal" name="name" />
							</div>
							<div class="col-md-4">
								<span class="span-placeholder">Teléfono (10 dígitos)</span>
								<input 
                  v-bind:disabled="isDatoAdicionalEdit" 
                  v-bind:class="[ isDatoAdicionalEdit ? 'vs-input bg-toggle-disable' : 'vs-input bg-toggle-enable']" 
                  label="Teléfono (10 dígitos)" 
                  v-model="adicionales.propietarioInmueble.telefono" name="name" />
							</div>
							<div class="col-md-4">
								<span class="span-placeholder">RFC</span>
								<input 
                  v-bind:disabled="isDatoAdicionalEdit" 
                  v-bind:class="[ isDatoAdicionalEdit ? 'vs-input bg-toggle-disable' : 'vs-input bg-toggle-enable']" 
                  label="RFC" v-model="adicionales.propietarioInmueble.rfc" name="name" />
							</div>
						</div>

						<br />
					</div>
				</div>
			</div>
			<div class="accordion-item">
				<h2 class="accordion-header" id="headingFive-dat">
					<button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseFive-dat" aria-expanded="false" aria-controls="collapseFive-dat">Bienes patrimoniales</button>
				</h2>
				<div id="collapseFive-dat" class="accordion-collapse collapse" aria-labelledby="headingFive-dat" data-bs-parent="#accordionExample4">
					<div class="accordion-body accordion-scrolling mh-4"><br />
            <h3>Propiedades</h3>
						<div class="row w-full">              
							<div class="col-md-4">                
								<span class="span-placeholder">Número de propiedades</span>
								<input 
                  v-bind:disabled="isDatoAdicionalEdit" 
                  v-bind:class="[ isDatoAdicionalEdit ? 'vs-input bg-toggle-disable' : 'vs-input bg-toggle-enable']" 
                  label="Número de propiedades" 
                  v-model="adicionales.propiedades.numero" name="name" v-on:keypress="isNumber($event)" />
							</div>
							<div class="col-md-4">
								<span class="span-placeholder">Valor aproximado</span>
								<input 
                  v-bind:disabled="isDatoAdicionalEdit" 
                  v-bind:class="[ isDatoAdicionalEdit ? 'vs-input bg-toggle-disable' : 'vs-input bg-toggle-enable']" 
                  label="Valor aproximado" v-model="adicionales.propiedades.valor" name="name" @change="formateaValorPropiedades()" v-on:keyup="validaValorPropiedades()" v-on:keypress="isNumber($event)" />
							</div>
							<div class="col-md-4">
								<span class="span-placeholder">Valor Gravado</span>
								<input 
                  v-bind:disabled="isDatoAdicionalEdit" 
                  v-bind:class="[ isDatoAdicionalEdit ? 'vs-input bg-toggle-disable' : 'vs-input bg-toggle-enable']" 
                  label="Valor Gravado" v-model="adicionales.propiedades.valorGravado" name="name" @change="formateaValorGravado()" v-on:keyup="validaValorGravado()" v-on:keypress="isNumber($event)" />
							</div>
						</div>
					
            <br /><h3>Vehículos</h3>
						<div class="row w-full">
							<div class="col-md-4">
								<span class="span-placeholder">Número de autos</span>
								<input 
                  v-bind:disabled="isDatoAdicionalEdit" 
                  v-bind:class="[ isDatoAdicionalEdit ? 'vs-input bg-toggle-disable' : 'vs-input bg-toggle-enable']" 
                  label="Número de autos" 
                  v-model="adicionales.vehiculos.numero" name="name" v-on:keypress="isNumber($event)" />
							</div>
						</div>
						<div class="row w-full">
							<div class="col-md-4">
								<span class="span-placeholder">Valor aproximado vehículo 1</span>
								<input 
                  v-bind:disabled="isDatoAdicionalEdit" 
                  v-bind:class="[ isDatoAdicionalEdit ? 'vs-input bg-toggle-disable' : 'vs-input bg-toggle-enable']" 
                  label="Valor aproximado vehículo 1" 
                  v-model="adicionales.vehiculos.valor" name="name" @change="formateaValorVehiculo()" v-on:keyup="validaValorVehiculo()" v-on:keypress="isNumber($event)" />
							</div>
							<div class="col-md-4">
								<span class="span-placeholder">Marca vehículo 1</span>
								<input 
                  v-bind:disabled="isDatoAdicionalEdit" 
                  v-bind:class="[ isDatoAdicionalEdit ? 'vs-input bg-toggle-disable' : 'vs-input bg-toggle-enable']" 
                  label="Marca vehículo 1" 
                  v-model="adicionales.vehiculos.marca" name="name" />
							</div>
							<div class="col-md-4">
								<span class="span-placeholder">Modelo vehículo 1</span>
								<input 
                  v-bind:disabled="isDatoAdicionalEdit" 
                  v-bind:class="[ isDatoAdicionalEdit ? 'vs-input bg-toggle-disable' : 'vs-input bg-toggle-enable']" 
                  label="Modelo vehículo 1" 
                  v-model="adicionales.vehiculos.modelo" name="name" />
							</div>
						</div>
						<div class="row w-full">
							<div class="col-md-4">
								<span class="span-placeholder" for="grados">Año vehículo 1</span>
								<select 
                  v-bind:disabled="isDatoAdicionalEdit" 
                  name="cars" id="grados" 
                  v-model="adicionales.vehiculos.anio" 
                  v-bind:class="[ isDatoAdicionalEdit ? 'vs-input bg-toggle-disable' : 'vs-input bg-toggle-enable']"
                >
									<option :value="''">Seleccione...</option>
									<option :value="tipo.Id" v-for="tipo in años" :key="tipo.Id">{{ tipo.Nombre }}</option>
								</select>
							</div>
							<div class="col-md-8 text-center">
								<span class="span-placeholder" for="pago">Pago vehículo 1</span>
								<div class="row">
									<div class="col-md-6 content-radio">
										<vs-radio v-bind:disabled="isDatoAdicionalEdit" v-model="adicionales.vehiculos.pago" vs-name="pago" name="pago" vs-value="Completo"></vs-radio>
										<span class="span-placeholder" for="Completo">Completo</span>
									</div>
									<div class="col-md-6 content-radio">
										<vs-radio v-bind:disabled="isDatoAdicionalEdit" v-model="adicionales.vehiculos.pago" vs-name="pago" name="pago" vs-value="Parcial"></vs-radio>
										<span class="span-placeholder" for="Parcial">Parcial</span>
									</div>
								</div>
							</div>
						</div>
						<div class="row w-full">
							<div class="col-md-4">
								<span class="span-placeholder">Valor aproximado vehículo 2</span>
								<input 
                  v-bind:disabled="isDatoAdicionalEdit" 
                  v-bind:class="[ isDatoAdicionalEdit ? 'vs-input bg-toggle-disable' : 'vs-input bg-toggle-enable']" 
                  label="Valor aproximado vehículo 2" 
                  v-model="adicionales.vehiculos.valor2" name="name" @change="formateaValorVehiculo2()" v-on:keyup="validaValorVehiculo2()" v-on:keypress="isNumber($event)" />
							</div>
							<div class="col-md-4">
								<span class="span-placeholder">Marca vehículo 2</span>
								<input 
                  v-bind:disabled="isDatoAdicionalEdit" 
                  v-bind:class="[ isDatoAdicionalEdit ? 'vs-input bg-toggle-disable' : 'vs-input bg-toggle-enable']" 
                  label="Marca vehículo 2" 
                  v-model="adicionales.vehiculos.marca2" name="name" />
							</div>
							<div class="col-md-4">
								<span class="span-placeholder">Modelo vehículo 2</span>
								<input 
                  v-bind:disabled="isDatoAdicionalEdit" 
                  v-bind:class="[ isDatoAdicionalEdit ? 'vs-input bg-toggle-disable' : 'vs-input bg-toggle-enable']" 
                  label="Modelo vehículo 2" 
                  v-model="adicionales.vehiculos.modelo2" name="name" />
							</div>
						</div>
						<div class="row w-full">
							<div class="col-md-4">
								<span class="span-placeholder" for="grados">Año vehículo 2</span>
								<select 
                  v-bind:disabled="isDatoAdicionalEdit" 
                  name="cars" 
                  id="grados" 
                  v-model="adicionales.vehiculos.anio2" 
                  v-bind:class="[ isDatoAdicionalEdit ? 'vs-input bg-toggle-disable' : 'vs-input bg-toggle-enable']"
                >
									<option :value="''">Seleccione...</option>
									<option :value="tipo.Id" v-for="tipo in años" :key="tipo.Id">{{ tipo.Nombre }}</option>
								</select>
							</div>
							<div class="col-md-8 text-center">
								<span class="span-placeholder" for="pago2">Pago vehículo 2</span>
								<div class="row">
									<div class="col-md-6 content-radio">
										<vs-radio v-bind:disabled="isDatoAdicionalEdit" v-model="adicionales.vehiculos.pago2" vs-name="pago2" name="pago2" vs-value="Completo"></vs-radio>
										<span class="span-placeholder" for="Completo">Completo</span>
									</div>
									<div class="col-md-6 content-radio">
										<vs-radio v-bind:disabled="isDatoAdicionalEdit" v-model="adicionales.vehiculos.pago2" vs-name="pago2" name="pago2" vs-value="Parcial"></vs-radio>
										<span class="span-placeholder" for="Parcial">Parcial</span>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			<!--<div class="accordion-item">
				<h2 class="accordion-header" id="headingSix-dat">
					<button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseSix-dat" aria-expanded="false" aria-controls="collapseSix-dat">Vehículos</button>
				</h2>
				<div id="collapseSix-dat" class="accordion-collapse collapse" aria-labelledby="headingSix-dat" data-bs-parent="#accordionExample4">
					<div class="accordion-body accordion-scrolling mh-4">
						<div class="row w-full">
							<div class="col-md-4">
								<span class="span-placeholder">Número de autos</span>
								<input 
                  v-bind:disabled="isDatoAdicionalEdit" 
                  v-bind:class="[ isDatoAdicionalEdit ? 'vs-input bg-toggle-disable' : 'vs-input bg-toggle-enable']" 
                  label="Número de autos" 
                  v-model="adicionales.vehiculos.numero" name="name" v-on:keypress="isNumber($event)" />
							</div>
						</div>
						<div class="row w-full">
							<div class="col-md-4">
								<span class="span-placeholder">Valor aproximado vehículo 1</span>
								<input 
                  v-bind:disabled="isDatoAdicionalEdit" 
                  v-bind:class="[ isDatoAdicionalEdit ? 'vs-input bg-toggle-disable' : 'vs-input bg-toggle-enable']" 
                  label="Valor aproximado vehículo 1" 
                  v-model="adicionales.vehiculos.valor" name="name" @change="formateaValorVehiculo()" v-on:keyup="validaValorVehiculo()" v-on:keypress="isNumber($event)" />
							</div>
							<div class="col-md-4">
								<span class="span-placeholder">Marca vehículo 1</span>
								<input 
                  v-bind:disabled="isDatoAdicionalEdit" 
                  v-bind:class="[ isDatoAdicionalEdit ? 'vs-input bg-toggle-disable' : 'vs-input bg-toggle-enable']" 
                  label="Marca vehículo 1" 
                  v-model="adicionales.vehiculos.marca" name="name" />
							</div>
							<div class="col-md-4">
								<span class="span-placeholder">Modelo vehículo 1</span>
								<input 
                  v-bind:disabled="isDatoAdicionalEdit" 
                  v-bind:class="[ isDatoAdicionalEdit ? 'vs-input bg-toggle-disable' : 'vs-input bg-toggle-enable']" 
                  label="Modelo vehículo 1" 
                  v-model="adicionales.vehiculos.modelo" name="name" />
							</div>
						</div>
						<div class="row w-full">
							<div class="col-md-4">
								<span class="span-placeholder" for="grados">Año vehículo 1</span>
								<select 
                  v-bind:disabled="isDatoAdicionalEdit" 
                  name="cars" id="grados" 
                  v-model="adicionales.vehiculos.anio" 
                  v-bind:class="[ isDatoAdicionalEdit ? 'vs-input bg-toggle-disable' : 'vs-input bg-toggle-enable']"
                >
									<option :value="''">Seleccione...</option>
									<option :value="tipo.Id" v-for="tipo in años" :key="tipo.Id">{{ tipo.Nombre }}</option>
								</select>
							</div>
							<div class="col-md-8 text-center">
								<span class="span-placeholder" for="pago">Pago vehículo 1</span>
								<div class="row">
									<div class="col-md-6 content-radio">
										<vs-radio v-bind:disabled="isDatoAdicionalEdit" v-model="adicionales.vehiculos.pago" vs-name="pago" name="pago" vs-value="Completo"></vs-radio>
										<span class="span-placeholder" for="Completo">Completo</span>
									</div>
									<div class="col-md-6 content-radio">
										<vs-radio v-bind:disabled="isDatoAdicionalEdit" v-model="adicionales.vehiculos.pago" vs-name="pago" name="pago" vs-value="Parcial"></vs-radio>
										<span class="span-placeholder" for="Parcial">Parcial</span>
									</div>
								</div>
							</div>
						</div>
						<div class="row w-full">
							<div class="col-md-4">
								<span class="span-placeholder">Valor aproximado vehículo 2</span>
								<input 
                  v-bind:disabled="isDatoAdicionalEdit" 
                  v-bind:class="[ isDatoAdicionalEdit ? 'vs-input bg-toggle-disable' : 'vs-input bg-toggle-enable']" 
                  label="Valor aproximado vehículo 2" 
                  v-model="adicionales.vehiculos.valor2" name="name" @change="formateaValorVehiculo2()" v-on:keyup="validaValorVehiculo2()" v-on:keypress="isNumber($event)" />
							</div>
							<div class="col-md-4">
								<span class="span-placeholder">Marca vehículo 2</span>
								<input 
                  v-bind:disabled="isDatoAdicionalEdit" 
                  v-bind:class="[ isDatoAdicionalEdit ? 'vs-input bg-toggle-disable' : 'vs-input bg-toggle-enable']" 
                  label="Marca vehículo 2" 
                  v-model="adicionales.vehiculos.marca2" name="name" />
							</div>
							<div class="col-md-4">
								<span class="span-placeholder">Modelo vehículo 2</span>
								<input 
                  v-bind:disabled="isDatoAdicionalEdit" 
                  v-bind:class="[ isDatoAdicionalEdit ? 'vs-input bg-toggle-disable' : 'vs-input bg-toggle-enable']" 
                  label="Modelo vehículo 2" 
                  v-model="adicionales.vehiculos.modelo2" name="name" />
							</div>
						</div>
						<div class="row w-full">
							<div class="col-md-4">
								<span class="span-placeholder" for="grados">Año vehículo 2</span>
								<select 
                  v-bind:disabled="isDatoAdicionalEdit" 
                  name="cars" 
                  id="grados" 
                  v-model="adicionales.vehiculos.anio2" 
                  v-bind:class="[ isDatoAdicionalEdit ? 'vs-input bg-toggle-disable' : 'vs-input bg-toggle-enable']"
                >
									<option :value="''">Seleccione...</option>
									<option :value="tipo.Id" v-for="tipo in años" :key="tipo.Id">{{ tipo.Nombre }}</option>
								</select>
							</div>
							<div class="col-md-8 text-center">
								<span class="span-placeholder" for="pago2">Pago vehículo 2</span>
								<div class="row">
									<div class="col-md-6 content-radio">
										<vs-radio v-bind:disabled="isDatoAdicionalEdit" v-model="adicionales.vehiculos.pago2" vs-name="pago2" name="pago2" vs-value="Completo"></vs-radio>
										<span class="span-placeholder" for="Completo">Completo</span>
									</div>
									<div class="col-md-6 content-radio">
										<vs-radio v-bind:disabled="isDatoAdicionalEdit" v-model="adicionales.vehiculos.pago2" vs-name="pago2" name="pago2" vs-value="Parcial"></vs-radio>
										<span class="span-placeholder" for="Parcial">Parcial</span>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>-->
			<div class="accordion-item">
				<h2 class="accordion-header" id="headingSeven-dat">
					<button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseSeven-dat" aria-expanded="false" aria-controls="collapseSeven-dat">Otros</button>
				</h2>
				<div id="collapseSeven-dat" class="accordion-collapse collapse" aria-labelledby="headingSeven-dat" data-bs-parent="#accordionExample4">
					<div class="accordion-body accordion-scrolling mh-4">
						<div class="row w-full">
							<div class="col-md-6">
								<span class="span-placholder" for="">¿Desea tramitar tarjeta de crédito (ScotiaBank)?</span>
								<vs-switch v-bind:disabled="isDatoAdicionalEdit" v-model="adicionales.otros.tramitarTarjetaCreditoScotiaBank" />
							</div>
							<div class="col-md-6">
								<span class="span-placeholder" for="">¿Consultar Estado de cuenta Online?</span>
								<vs-switch v-bind:disabled="isDatoAdicionalEdit" v-model="adicionales.otros.consultarEstadoCuentaOnline" />
							</div>
						</div>
						<div class="row w-full">
							<div class="col-md-6">
								<span class="span-placeholder" for="">¿Oferta Vinculante?</span>
								<vs-switch v-bind:disabled="isDatoAdicionalEdit" v-model="adicionales.otros.ofertaVinculante" />
							</div>
							<div class="col-md-6">
								<span class="span-placeholder" for="">Quiero recibir Publicidad y mercadeo</span>
								<vs-switch v-bind:disabled="isDatoAdicionalEdit" v-model="adicionales.otros.recibirPublicidadMercadeo" />
							</div>
						</div>
						<div class="row w-full">
							<div class="col-md-6">
								<span class="span-placeholder" for="">Autorizo el tratamiento de mis datos (Afirme)</span>
								<vs-switch v-bind:disabled="isDatoAdicionalEdit" v-model="adicionales.otros.autorizarTratamientoDatosAfirme" />
							</div>
							<div class="col-md-6">
								<span class="span-placeholder" for="">Autorizo impresión de número de crédito en edo de cuenta (Banorte)</span>
								<vs-switch v-bind:disabled="isDatoAdicionalEdit" v-model="adicionales.otros.autorizarImpresionNumeroCreditoEstadoCuentaBanorte" />
							</div>
						</div>
						<div class="row w-full">
							<div class="col-md-6">
								<span class="span-placeholder" for="">Autorizo impresión de nombre en Edo de cuenta (Banorte)</span>
								<vs-switch v-bind:disabled="isDatoAdicionalEdit" v-model="adicionales.otros.autorizarImpresionNombreEstadoCuentaBanorte" />
							</div>
							<div class="col-md-6">
								<span class="span-placeholder" for="">Autorizo ser contactado en horario laboral (Banorte)</span>
								<vs-switch v-bind:disabled="isDatoAdicionalEdit" v-model="adicionales.otros.autorizarContactoHorarioLaboralBanorte" />
							</div>
						</div>
						<div class="row w-full">
							<div class="col-md-6">
								<span class="span-placeholder" for="">Deseo contratar el paquete integral (Banorte)</span>
								<vs-switch v-bind:disabled="isDatoAdicionalEdit" v-model="adicionales.otros.contratarPaqueteIntegralBanorte" />
							</div>
							<div class="col-md-6">
								<span class="span-placeholder" for="">Comisión por apertura (ScotiaBank) Prepago NO</span>
								<vs-switch v-bind:disabled="isDatoAdicionalEdit" v-model="adicionales.otros.comisionPorAperturaPrepagoNoScotiaBank" />
							</div>
						</div>
						<div class="row w-full">
							<div class="col-md-6">
								<div class="div-select row w-full w-full">
									<span class="span-placeholder" for="formasEnganche">Si ústed no es cliente del Banco selecionado, le gustaría ser atendido en una sucursal cercana al domicilio de su</span>
									<select 
                    v-bind:disabled="isDatoAdicionalEdit" 
                    name="cars" 
                    id="formasEnganche" 
                    v-model="adicionales.otros.tipoLugarAtencion" 
                    v-bind:class="[ isDatoAdicionalEdit ? 'vs-input bg-toggle-disable' : 'vs-input bg-toggle-enable']"
                  >
										<option :value="''">Seleccione...</option>
										<option :value="tipo.Id" v-for="tipo in tiposLugaresAtencion" :key="tipo.Id">{{ tipo.Nombre }}</option>
									</select>
								</div>
							</div>
							<div class="col-md-6">
								<div class="div-select row w-full w-full">
									<span class="span-placeholder" for="formasEnganche">Tiene alguna relación familiar con un Consejero o Alto Funcionario del Grupo Financiero Santander México</span>
									<select 
                    v-bind:disabled="isDatoAdicionalEdit" 
                    name="cars" id="formasEnganche" 
                    v-model="adicionales.otros.referenciaFamiliarFinanciera" 
                    v-bind:class="[ isDatoAdicionalEdit ? 'vs-input bg-toggle-disable' : 'vs-input bg-toggle-enable']"
                  >
										<option :value="''">Seleccione...</option>
										<option :value="tipo.Id" v-for="tipo in parentescos" :key="tipo.Id">{{ tipo.Nombre }}</option>
									</select>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
		<div class="row w-full">
			<div class="col-md-4">
				<!-- <button class="btn btn-second" @click="registraAdicionales()">Guardar adicionales</button> -->
			</div>
		</div>
	</div>
</template>

<script>
import vSelect from 'vue-select'
import { nacionalidades } from '@/assets/jsonFiles/nacionalidades.js'
import { paisesNacimiento } from '@/assets/jsonFiles/paisesNacimiento.js'
export default {
  components: {
    'v-select': vSelect,
  },
  data(){
		return{
      SeguroDanosCiti: '', // se agrega porque genera errores
      SeguroInsolutoCiti: '', // se agrega porque genera errores
      SeguroMontoCiti: '', // se agrega porque genera errores

      isDatoAdicionalEdit: true,
      nacionalidades: [],
      paisesNac: [],

      años:[],
      SolswitchAfecMedicas: false,
      SolswitchCiti: false,
      SolswitchOtro: false,
      SolswitchAcLaboral: false,
      SolswitchHabitos: false,
      SolswitchHistorial: false,
      SolswitchProMedicos: false,
      padecimientosBase: [{ Id: '', Nombre: '' }],
      padecimientos:[],
      padecimientosOpcionesAfecciones: [], 
      padecimientosOpcionesProcedimientos: [], 
      padecimientosOpcionesHistorial: [], 
      padecimientosOpcionesHabitos: [],
      padecimientosOpcionesAcLaboral:[],
      padecimientosOpcionesOtro:[],
      companiasCelular: [
        {name: 'Telcel'},
        {name: 'AT&T'},
        {name: 'Movistar'},
        {name: 'Unefon'},
        {name: 'Virgin Mobile'},
        {name: 'Freedompop'},
        {name: 'Flash Mobile'},
        {name: 'Weex'},
        {name: 'Cierto'},
        {name: 'Maz Tiempo'},
        {name: 'Oui móvil'},
        {name: 'Oxxo'},
        {name: 'Izzi móvil'},
        {name: 'Pillofon'},
        {name: 'BlueTelecomm'},
        {name: 'YO Mobile'},
        {name: 'Bait'},
        {name: 'Sky Celular'}
      ],
      Afecciones:[1,2,3,4,5,6,8,9,11,12,13,14,16,20,38,41,54,60,61,62,63,67,68,69],
      Procedimientos:[10,17,35,36],
      Historial:[7,15,22,37,39,44,45,46,47,48,49,64,70], 
      Habitos:[21,23,26,31,59],
      AcLaboral:[18,19,25,30,42,43,53,58,65,66], 
      Otro:[24,33,34,40,50,51,52,55,57],         
      adicionales:{
        seguros:true,
        formaEnganche:'',
        tiempoOcupandoDomicilioActual:'0',
        tiempoOcupandoDomicilioActualMeses:'0',
        antiguedadInmuebleAdquirir:'',
        antiguedadCiudad:'0',
        antiguedadCiudadMeses:'0',
        antiguedadDomicilioAnterior:'',
        institucionEstudios:'',
        gradoEstudios:'',
        montoPension:'',
        otroNombre:'',
        // nacionalidad:'',
        estadoNacimiento:'',
        municipioNacimiento:'',
        paisNacimiento:'',
        telefonoFijo:'',
        telefonoOficina:'',
        identificacionPresenta:'',
        numeroIfe:'',
        nss:'',
        issste:'',
        vigencia:null,
        fiel:'',
        companiaCelular:'',
        titularidadDomicilio:'',
        totalGastosMensuales:'',
        lugarSolicitud:'',
        FechaSolicitud:null,
        usoInmueble:'',
        tipoInmueble:'',
        calleExtranjero:'',
        numeroExteriorExtranjero:'',
        numeroInteriorExtranjero:'',
        coloniaExtranjero:'',
        estadoExtranjero:'',
        paisExtranjero:'',
        codigoPostalExtranjero:'',
        NacionalidadDetalle:'',
        PaisRecidencia:'',
        nombreAsesor:'',
        rfcAsesor:'',
        inmuebleGarantia:{
          calle:'',
          numero:'',
          colonia:'',
          municipio:'',
          ciudad:'',
          estado:'',
          pais:'',
          codigo:'',
          lote:'',
          manzana:'',
          valorEstimado:'',
          superficieTerreno:'',
          areaConstruccion:'',
          descripcion:'',
        },
        empresa:{
          nombre:'',
          rfc:'',
          representanteLegal:'',
          apellidoPaternoRepresentante:'',
          apellidoMaternoRepresentante:'',
          representanteLegalRfc:''
        },
        propietarioInmueble:{
          nombre:'',
          apellidoPaterno:'',
          apellidoMaterno:'',
          calle:'',
          numeroExterior:'',
          colonia:'',
          municipio:'',
          ciudad:'',
          estado:'',
          pais:'',
          codigoPostal:'',
          telefono:'',
          rfc:'',
          estadoCivil:'',
        },
        propiedades:{
          numero:'',
          valor:'',
          valorGravado:'',
          tipoInmueble:''
        },
        vehiculos:{
          numero:'',
          valor:'',
          marca:'',
          modelo:'',
          anio:'',
          pago: '',
          valor2:'',
          marca2:'',
          modelo2:'',
          anio2:'',
          pago2:''
        },
        otros:{
          consultarEstadoCuentaOnline:false,
          ofertaVinculante:false,       
          recibirPublicidadMercadeo:false,
          autorizarTratamientoDatosAfirme:false,
          tramitarTarjetaCreditoScotiaBank:false,
          comisionPorAperturaPrepagoNoScotiaBank:false,
          autorizarImpresionNumeroCreditoEstadoCuentaBanorte:false,
          autorizarImpresionNombreEstadoCuentaBanorte:false,
          autorizarContactoHorarioLaboralBanorte:false,
          contratarPaqueteIntegralBanorte:false,
          tipoLugarAtencion:'',
          ReferenciaFamiliarFinanciera:'',
        }
      },
      formasEnganche:[],
      usosInmuebles:[],
      domicilioOcupaciones:[],
      gradosEstudio:[],
      estados:[],
      tiposInmuebles:[],
      identificaciones:[],
      estadosCiviles:[],
      parentescos:[],
      tiposLugaresAtencion:[],
      id:'',
      IdCaso:'',
      email:'',
      solicitante:{},
      gradoEstudiosScotiabank:'',
      tipoInmuebleScotiabank:'',
      tipoUsoInmuebleScotabank:'',
      peso: '',
      estatura: '',
      tipoSeguro: '',
      seguroDanos: false,
      isActiveContentCiti: false,
      AccreditedidV3:false,
      // header: {
			// 		"authorization": "Bearer 16JXvQ9CD1eEoVESY3-4d-bElrAAgAfcB0UgYwAB-XyuNugkcBvgntr6n6VjEAFWenS9Exi_jlLv_RBfmf004TXytHP64dNEuSyhlXOBDtO56hW07txWd4evNWfukK5CO1ETRIOk8GWyjjS7daQ8eLHUz3XefoKEHKE0yIy76o8rZZYTGskAn1Zo3oKcPFzfs_fmVlO15cD_Kxx27-1qqDBsfAphhY7N599ly5ppo5L4XHaL8JAv6mTxXVYxrTZPQDmnLqQ9Nwre7Bd4URM5rR3rVMrAVkPzJ8ohQ9-pgvigB-Fra_SMi31c5T-QrSTB"
			// 		},
      header: {},
        solicitanteV3 : '',
      SocGuid:'',
      BrokerId:''
    }
  },
  mounted(){
    this.id=this.$route.params.id
    this.getNacionalidades()
    this.getPaisesNacimiento()
    this.getApikey()
    
    this.getFormasEnganche()
    this.getUsoInmueble()
    this.getListaDomicilioOcupaciones()
    this.getGradosEstudio()
    this.getEstados()
    this.getIdentificaciones()
    this.getTiposInmuebles()
    this.getEstadosCiviles()
    this.getListaTipoLugarAtencion()
    this.getParentescos()
    this.getAños()
    this.listaEnfermedades()
    this.dameCaso()
    this.DameCredenciales()

  },
  watch: {
		// Formato mayusculas sin acento
		'adicionales.nombreAsesor': function(newValue, oldValue) {
			this.adicionales.nombreAsesor = this.textFormat(newValue);
		},
		'adicionales.empresa.representanteLegal': function(newValue, oldValue) {
			this.adicionales.empresa.representanteLegal = this.textFormat(newValue);
		},
		'adicionales.empresa.apellidoPaternoRepresentante': function(newValue, oldValue) {
			this.adicionales.empresa.apellidoPaternoRepresentante = this.textFormat(newValue);
		},
		'adicionales.empresa.apellidoMaternoRepresentante': function(newValue, oldValue) {
			this.adicionales.empresa.apellidoMaternoRepresentante = this.textFormat(newValue);
		},
		'adicionales.propietarioInmueble.nombre': function(newValue, oldValue) {
			this.adicionales.propietarioInmueble.nombre = this.textFormat(newValue);
		},
		'adicionales.propietarioInmueble.apellidoPaterno': function(newValue, oldValue) {
			this.adicionales.propietarioInmueble.apellidoPaterno = this.textFormat(newValue);
		},
		'adicionales.propietarioInmueble.apellidoMaterno': function(newValue, oldValue) {
			this.adicionales.propietarioInmueble.apellidoMaterno = this.textFormat(newValue);
		},
    pension: function(newValue) {
      if (this.adicionales.montoPension != null ) {
        this.adicionales.montoPension= newValue.replace(/\D/g, "").replace(/\B(?=(\d{3})+(?!\d))/g, ",")
      }
    },
    totalMensuales: function(newValue) {
      if (this.adicionales.totalGastosMensuales != null ) {
        this.adicionales.totalGastosMensuales= newValue.replace(/\D/g, "").replace(/\B(?=(\d{3})+(?!\d))/g, ",")
      }
    },
    valorEstimado: function(newValue) {
      if (this.adicionales.inmuebleGarantia.valorEstimado != null ) {
        this.adicionales.inmuebleGarantia.valorEstimado= newValue.replace(/\D/g, "").replace(/\B(?=(\d{3})+(?!\d))/g, ",")
      }
    },
    /*propiedadesValor: function(newValue) {
      if (this.adicionales.propiedades.valor != null ) {
        this.adicionales.propiedades.valor= newValue.replace(/\D/g, "").replace(/\B(?=(\d{3})+(?!\d))/g, ",")
      }
    },
    propiedadesValorGravado: function(newValue) {
      if (this.adicionales.propiedades.valorGravado != null ) {
        this.adicionales.propiedades.valorGravado= newValue.replace(/\D/g, "").replace(/\B(?=(\d{3})+(?!\d))/g, ",")
      }
    },
    vehiculosValor:function(newValue){
      if (this.adicionales.vehiculos.valor != null ) {
        this.adicionales.vehiculos.valor= newValue.replace(/\D/g, "").replace(/\B(?=(\d{3})+(?!\d))/g, ",")
      }
    },
    vehiculosValor2:function(newValue){
      if (this.adicionales.vehiculos.valor2 != null ) {
        this.adicionales.vehiculos.valor2= newValue.replace(/\D/g, "").replace(/\B(?=(\d{3})+(?!\d))/g, ",")
      }
    }*/
  },
  computed:{
    totalMensuales(){
      return this.adicionales.totalGastosMensuales
    },
    valorEstimado(){
      return this.adicionales.inmuebleGarantia.valorEstimado
    },
    
    /*propiedadesValor(){
      return this.adicionales.propiedades.valor
    },
    propiedadesValorGravado(){
      return this.adicionales.propiedades.valorGravado
    },
    vehiculosValor(){
      return this.adicionales.vehiculos.valor
    },
    vehiculosValor2(){
      return this.adicionales.vehiculos.valor2
    },*/
    pension(){
      return this.adicionales.montoPension
    }
  },
  methods:{
		// Conversion
		textFormat(strValue) {
			return strValue.normalize('NFD')
							.replace(/([^n\u0300-\u036f]|n(?!\u0303(?![\u0300-\u036f])))[\u0300-\u036f]+/gi,"$1")
							.normalize()
							.toUpperCase(); // Eliminar acentos menos Ññ
		},
    async getTokenSisec() {
      let objRequestToken = {
        strApiKey: this.key,
        strMetodo: 'DameAuthToken',
      }
      await this.$axios.post('/', objRequestToken,
        { headers: { 'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8' } })
        .then( response => {
          // console.log('getTokenSisec', response.data.access_token );
          if (!response.data.error) {
            this.header = { "Authorization" : "Bearer "+ response.data.access_token};
          } else {
            this.$vs.notify({
              title: 'Ocurrio un error al obtener token',
              text: response.data.error_description,
              color: 'danger',
              position: 'top-right',
            })
          }
        }).catch(function (error) {
          self.$vs.notify({
            title: 'Ocurrio un error de sistema',
            text: error,
            color: 'danger',
            position: 'top-right',
          })
        });
    },
		getPaisesNacimiento() {
			this.paisesNac = paisesNacimiento;
		},
		getNacionalidades() {
			let nacionalidadesList = [];
			let index = 3;
			for (const nacionalidad of nacionalidades) {
				if (nacionalidad.Nombre === "MEXICANO (A)") {
					nacionalidadesList[0] = nacionalidad;
					continue;
				}
				if (nacionalidad.Nombre === "NORTEAMERICANO (A)") {
					nacionalidadesList[1] = nacionalidad;
					continue;
				}
				if (nacionalidad.Nombre === "CANADIENSE") {
					nacionalidadesList[2] = nacionalidad;
					continue;
				}
				nacionalidadesList[index] = nacionalidad;
				index ++;
			}
			this.nacionalidades = nacionalidadesList;
		},
		toggleSave() {
			this.isDatoAdicionalEdit = !this.isDatoAdicionalEdit;
		},

    validaValorVehiculo2() {
      this.adicionales.vehiculos.valor2=this.adicionales.vehiculos.valor2.replace(/,/g,"")
      const regex = /^(\d{1,8})(\.\d{0,2})?$/  ///^[0-9]{1,10}\.?[0-9]{0,2}$/ ///^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/
      const matches = regex.test(this.adicionales.vehiculos.valor2);
      if(matches===true){
      this.adicionales.vehiculos.valor2= this.adicionales.vehiculos.valor2// .toString().replace(exp,rep);
      }else{
        this.adicionales.vehiculos.valor2=this.adicionales.vehiculos.valor2.replace(/.$/, '')
      }
    },
     validaIngersoMensual() {
      this.estatura = this.estatura.replace(/,/g, '')
			const regex = /^(\d{1})(\.\d{0,2})?$/
			const matches = regex.test(this.estatura)
			if (matches === true) {
				this.estatura = this.estatura
			} else {
				this.estatura = this.estatura.replace(/.$/, '')
			}
    },
     validaIngersoMensualPeso() {
      this.peso = this.peso.replace(/,/g, '')
			const regex = /^(\d{1,3})?$/
			const matches = regex.test(this.peso)
			if (matches === true) {
				this.peso = this.peso
			} else {
				this.peso = this.peso.replace(/.$/, '')
			}
    },
    formateaValorVehiculo2(){
      if(this.adicionales.vehiculos.valor2==''){
        this.adicionales.vehiculos.valor2=0
      }
      this.adicionales.vehiculos.valor2 = parseFloat(this.adicionales.vehiculos.valor2).toFixed(2)
       this.adicionales.vehiculos.valor2=this.formateaValoresConsultados(this.adicionales.vehiculos.valor2)
    },
    validaValorVehiculo() {
      this.adicionales.vehiculos.valor=this.adicionales.vehiculos.valor.replace(/,/g,"")
      const regex = /^(\d{1,8})(\.\d{0,2})?$/  ///^[0-9]{1,10}\.?[0-9]{0,2}$/ ///^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/
      const matches = regex.test(this.adicionales.vehiculos.valor);
      if(matches===true){
      this.adicionales.vehiculos.valor= this.adicionales.vehiculos.valor// .toString().replace(exp,rep);
      }else{
        this.adicionales.vehiculos.valor=this.adicionales.vehiculos.valor.replace(/.$/, '')
      }
    },
    formateaValorVehiculo(){
      if(this.adicionales.vehiculos.valor==''){
        this.adicionales.vehiculos.valor=0
      }
      this.adicionales.vehiculos.valor = parseFloat(this.adicionales.vehiculos.valor).toFixed(2)
       this.adicionales.vehiculos.valor=this.formateaValoresConsultados(this.adicionales.vehiculos.valor)
    },
    formateaValoresConsultados(valor){
       valor=valor //.toFixed(2)
       return valor.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
    },
    validaValorGravado() {
      this.adicionales.propiedades.valorGravado=this.adicionales.propiedades.valorGravado.replace(/,/g,"")
      const regex = /^(\d{1,8})(\.\d{0,2})?$/  ///^[0-9]{1,10}\.?[0-9]{0,2}$/ ///^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/
      const matches = regex.test(this.adicionales.propiedades.valorGravado);
      if(matches===true){
      this.adicionales.propiedades.valorGravado= this.adicionales.propiedades.valorGravado// .toString().replace(exp,rep);
      }else{
        this.adicionales.propiedades.valorGravado=this.adicionales.propiedades.valorGravado.replace(/.$/, '')
      }
    },
    formateaValorGravado(){
      if(this.adicionales.propiedades.valorGravado==''){
        this.adicionales.propiedades.valorGravado=0
      }
      this.adicionales.propiedades.valorGravado = parseFloat(this.adicionales.propiedades.valorGravado).toFixed(2)
       this.adicionales.propiedades.valorGravado=this.formateaValoresConsultados(this.adicionales.propiedades.valorGravado)
    },
    validaValorPropiedades() {
      this.adicionales.propiedades.valor=this.adicionales.propiedades.valor.replace(/,/g,"")
      const regex = /^(\d{1,8})(\.\d{0,2})?$/  ///^[0-9]{1,10}\.?[0-9]{0,2}$/ ///^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/
      const matches = regex.test(this.adicionales.propiedades.valor);
      if(matches===true){
      this.adicionales.propiedades.valor= this.adicionales.propiedades.valor// .toString().replace(exp,rep);
      }else{
        this.adicionales.propiedades.valor=this.adicionales.propiedades.valor.replace(/.$/, '')
      }
    },
    formateaValorPropiedades(){
      if(this.adicionales.propiedades.valor==''){
        this.adicionales.propiedades.valor=0
      }
      this.adicionales.propiedades.valor = parseFloat(this.adicionales.propiedades.valor).toFixed(2)
      this.adicionales.propiedades.valor=this.formateaValoresConsultados(this.adicionales.propiedades.valor)
    },
     formateaAltura(){

      this.estatura = this.estatura.toString().match(/^-?\d+(?:\.\d{0,2})?/)[0];


      const exp = /(\d)(?=(\d{3})+(?!\d))/g;  
      const rep = '$1,';
      this.estatura= this.estatura.toString().replace(exp,rep);
    },
    registraAdicionales(){
      let self=this
      
      if(this.adicionales.gradoEstudios=='1'){
          this.gradoEstudiosScotiabank='O'
      }else if(this.adicionales.gradoEstudios=='2'){
          this.gradoEstudiosScotiabank='O'
      }else if(this.adicionales.gradoEstudios=='3'){
          this.gradoEstudiosScotiabank='O'
      }else if(this.adicionales.gradoEstudios=='4'){
          this.gradoEstudiosScotiabank='H'
      }else if(this.adicionales.gradoEstudios=='5'){
          this.gradoEstudiosScotiabank='O'
      }else if(this.adicionales.gradoEstudios=='6'){
          this.gradoEstudiosScotiabank='G'
      }else if(this.adicionales.gradoEstudios=='7'){
          this.gradoEstudiosScotiabank='H'
      }else if(this.adicionales.gradoEstudios=='8'){
          this.gradoEstudiosScotiabank='G'
      }else if(this.adicionales.gradoEstudios=='9'){
          this.gradoEstudiosScotiabank='G'
      }else if(this.adicionales.gradoEstudios=='10'){
          this.gradoEstudiosScotiabank='S'
      }

      if(this.adicionales.propiedades.tipoInmueble=='1'){
          this.tipoInmuebleScotiabank='C'
      }else if(this.adicionales.propiedades.tipoInmueble=='2'){
          this.tipoInmuebleScotiabank='D'
      }else if(this.adicionales.propiedades.tipoInmueble=='3'){
          this.tipoInmuebleScotiabank='L'
      }else if(this.adicionales.propiedades.tipoInmueble=='4'){
          this.tipoInmuebleScotiabank='O'
      }else if(this.adicionales.propiedades.tipoInmueble=='5'){
          this.tipoInmuebleScotiabank='O'
      }else if(this.adicionales.propiedades.tipoInmueble=='6'){
          this.tipoInmuebleScotiabank='F'
      }else if(this.adicionales.propiedades.tipoInmueble=='7'){
          this.tipoInmuebleScotiabank='O'
      }else if(this.adicionales.propiedades.tipoInmueble=='8'){
          this.tipoInmuebleScotiabank='O'
      }else if(this.adicionales.propiedades.tipoInmueble=='9'){
          this.tipoInmuebleScotiabank='O'
      }else if(this.adicionales.propiedades.tipoInmueble=='10'){
          this.tipoInmuebleScotiabank='T'
      }

      if(this.adicionales.titularidadDomicilio=='1'){
          this.tipoUsoInmuebleScotabank='R'
      }else if(this.adicionales.titularidadDomicilio=='2'){
          this.tipoUsoInmuebleScotabank='P'
      }else if(this.adicionales.titularidadDomicilio=='3'){
          this.tipoUsoInmuebleScotabank='H'
      }else if(this.adicionales.titularidadDomicilio=='4'){
          this.tipoUsoInmuebleScotabank='F'
      }else if(this.adicionales.titularidadDomicilio=='5'){
          this.tipoUsoInmuebleScotabank='O'
      }else if(this.adicionales.titularidadDomicilio=='6'){
          this.tipoUsoInmuebleScotabank='O'
      }

      var objRequestAdicionales = {
        strApiKey: this.key,
        strMetodo: 'RegistraAdicionalesSolicitud',
        objCaso: {
        Id: this.id,
       /* Personales:{
          IdentificacionPresenta:this.adicionales.identificacionPresenta,
          NumeroIdentificacion:this.adicionales.numeroIfe,
          Nss:this.adicionales.nss,
          Issste:this.adicionales.issste,
          Fiel:this.adicionales.fiel,
          CompaniaCelular:this.adicionales.companiaCelular,
          TelefonoFijo:this.adicionales.telefonoFijo,
          TelefonoOficina:this.adicionales.telefonoOficina,
          MontoPension:parseFloat(this.adicionales.montoPension.replace(/,/g,"")),
          TotalGastosMensuales:parseFloat(this.adicionales.totalGastosMensuales.replace(/,/g,"")),
          VigenciaIdentificacion:this.adicionales.vigencia,
          SeguroBancario:this.adicionales.seguros,
          CalleExtranjero:this.adicionales.calleExtranjero,
          NumeroExteriorExtranjero:this.adicionales.numeroExteriorExtranjero,
          NumeroInteriorExtranjero:this.adicionales.numeroInteriorExtranjero,
          ColoniaExtranjero:this.adicionales.coloniaExtranjero,
          EstadoExtranjero:this.adicionales.estadoExtranjero,
          // PaisExtranjero:this.adicionales.paisExtranjero,
          PaisExtranjero: {
            name: this.adicionales.paisExtranjero,
            noSanitiza: true,
          },
          CodigoPostalExtranjero:this.adicionales.codigoPostalExtranjero,
          // Se retira sanitiza del api por mantener simbolos
					NacionalidadDetalle: {
							name: this.adicionales.NacionalidadDetalle,
							noSanitiza: true,
					},
          // NacionalidadDetalle:this.adicionales.NacionalidadDetalle,
          // PaisRecidencia:this.adicionales.PaisRecidencia
        },*/
        /*Estudios:{
          GradoEstudios:this.adicionales.gradoEstudios,
          InstitucionEstudios:this.adicionales.institucionEstudios,
          GradoEstudiosScotiabank: this.gradoEstudiosScotiabank
        },*/
       /* Nacionalidad:{
          OtroNombre:this.adicionales.otroNombre,
          // EstadoNacimiento:this.adicionales.estadoNacimiento,
          // MunicipioNacimiento:this.adicionales.municipioNacimiento,
          // PaisNacimiento:this.adicionales.paisNacimiento,
        },*/
        /*DomicilioActual:{
          TiempoOcupandoDomicilioActual:this.adicionales.tiempoOcupandoDomicilioActual,
          TitularidadDomicilio:this.adicionales.titularidadDomicilio,
          TitularidadDomicilioScotiabank:this.tipoUsoInmuebleScotabank,
          AntiguedadCiudad:this.adicionales.antiguedadCiudad,
          AntiguedadDomicilioAnterior:this.adicionales.antiguedadDomicilioAnterior,
          
          AntiguedadEnCiudadMeses: this.adicionales.antiguedadCiudadMeses,
          TiempoViviendoCiudadMeses: this.adicionales.tiempoOcupandoDomicilioActualMeses,
        },*/
        /*InmuebleAdquirir:{
          FormaEnganche:this.adicionales.formaEnganche,
          LugarSolicitud:this.adicionales.lugarSolicitud,
          FechaSolicitud:this.adicionales.FechaSolicitud,
          UsoInmueble:this.adicionales.usoInmueble,
          TipoInmueble:this.adicionales.tipoInmueble,
          AntiguedadInmuebleAdquirir:this.adicionales.antiguedadInmuebleAdquirir,
        },*/
        InmuebleGarantia:{
          Calle:this.adicionales.inmuebleGarantia.calle,
          NumeroExterior:this.adicionales.inmuebleGarantia.numero,
          Colonia:this.adicionales.inmuebleGarantia.colonia,
          Municipio:this.adicionales.inmuebleGarantia.municipio,
          Ciudad:this.adicionales.inmuebleGarantia.ciudad,
          Estado:this.adicionales.inmuebleGarantia.estado,
          // Pais:this.adicionales.inmuebleGarantia.pais,
          Pais: {
            name: this.adicionales.inmuebleGarantia.pais,
            noSanitiza: true,
          },

          CodigoPostal:this.adicionales.inmuebleGarantia.codigo,
          Lote:this.adicionales.inmuebleGarantia.lote,
          Manzana:this.adicionales.inmuebleGarantia.manzana,
          ValorEstimado:parseFloat(this.adicionales.inmuebleGarantia.valorEstimado.replace(/,/g,"")),
          SuperficieTerreno:this.adicionales.inmuebleGarantia.superficieTerreno,
          AreaConstruccion:this.adicionales.inmuebleGarantia.areaConstruccion,
          Descripcion:this.adicionales.inmuebleGarantia.descripcion,
        },
        Inmobiliaria:{
          EmpresaPropietarioNombre:this.adicionales.empresa.nombre,
          EmpresaPropietarioRfc:this.adicionales.empresa.rfc,
          EmpresaPropietarioRepresentanteLegal:this.adicionales.empresa.representanteLegal,
          ApellidoPaternoRepresentante:this.adicionales.empresa.apellidoPaternoRepresentante,
          ApellidoMaternoRepresentante:this.adicionales.empresa.apellidoMaternoRepresentante,
          EmpresaPropietarioRfcRepresentanteLegal:this.adicionales.empresa.representanteLegalRfc,
          PropietarioNombre:this.adicionales.propietarioInmueble.nombre,
          PropietarioApellidoPaterno:this.adicionales.propietarioInmueble.apellidoPaterno,
          PropietarioApellidoMaterno:this.adicionales.propietarioInmueble.apellidoMaterno,
          PropietarioCalle:this.adicionales.propietarioInmueble.calle,
          PropietarioNumeroExterior:this.adicionales.propietarioInmueble.numeroExterior,
          PropietarioColonia:this.adicionales.propietarioInmueble.colonia,
          PropietarioMunicipio:this.adicionales.propietarioInmueble.municipio,
          PropietarioCiudad:this.adicionales.propietarioInmueble.ciudad,
          PropietarioEstado:this.adicionales.propietarioInmueble.estado,
          // PropietarioPais:this.adicionales.propietarioInmueble.pais,
          PropietarioPais: {
            name: this.adicionales.propietarioInmueble.pais,
            noSanitiza: true,
          },

          PropietarioCodigoPostal:this.adicionales.propietarioInmueble.codigoPostal,
          PropietarioTelefono:this.adicionales.propietarioInmueble.telefono,
          PropietarioRfc:this.adicionales.propietarioInmueble.rfc,
          PropietarioEstadoCivil:this.adicionales.propietarioInmueble.estadoCivil,
        },
        PropiedadesSolicitante:{
          NumeroInmuebles:this.adicionales.propiedades.numero,
          ValorAproximado:parseFloat(this.adicionales.propiedades.valor.replace(/,/g,"")),
          ValorGravado:parseFloat(this.adicionales.propiedades.valorGravado.replace(/,/g,"")),
          TipoInmueble:this.adicionales.propiedades.tipoInmueble,
          TipoInmuebleScotiabank: this.tipoInmuebleScotiabank,
          NumeroVehiculos:this.adicionales.vehiculos.numero,
          Auto1Valor:parseFloat(this.adicionales.vehiculos.valor.replace(/,/g,"")),
          Auto1Marca:this.adicionales.vehiculos.marca,
          Auto1Modelo:this.adicionales.vehiculos.modelo,
          Auto1Anio:this.adicionales.vehiculos.anio,
          Auto1Pago:this.adicionales.vehiculos.pago,

          Auto2Valor:parseFloat(this.adicionales.vehiculos.valor2.replace(/,/g,"")),
          Auto2Marca:this.adicionales.vehiculos.marca2,
          Auto2Modelo:this.adicionales.vehiculos.modelo2,
          Auto2Anio:this.adicionales.vehiculos.anio2,
          Auto2Pago:this.adicionales.vehiculos.pago2,
        },
        PreguntasSolicitud:{
          ConsultarEstadoCuentaOnline:this.adicionales.otros.consultarEstadoCuentaOnline,
          OfertaVinculante:this.adicionales.otros.ofertaVinculante,
          RecibirPublicidadMercadeo:this.adicionales.otros.recibirPublicidadMercadeo,
          AutorizarTratamientoDatos:this.adicionales.otros.autorizarTratamientoDatosAfirme,
          TramitarTarjetaCredito:this.adicionales.otros.tramitarTarjetaCreditoScotiaBank,
          ComisionPorAperturaPrepagoNo:this.adicionales.otros.comisionPorAperturaPrepagoNoScotiaBank,
          AutorizarImpresionNumeroCreditoEstadoCuenta:this.adicionales.otros.autorizarImpresionNumeroCreditoEstadoCuentaBanorte,
          AutorizarImpresionNombreEstadoCuenta:this.adicionales.otros.autorizarImpresionNombreEstadoCuentaBanorte,
          AutorizarContactoHorarioLaboral:this.adicionales.otros.autorizarContactoHorarioLaboralBanorte,
          ContrataPaqueteIntegral:this.adicionales.otros.contratarPaqueteIntegralBanorte,
          TipoLugarAtencion:this.adicionales.otros.tipoLugarAtencion,
          ReferenciaFamiliarFinanciera:this.adicionales.otros.referenciaFamiliarFinanciera
        }
      }
    }
      this.$axios.post('/',objRequestAdicionales,{headers : {'Content-Type':'application/x-www-form-urlencoded; charset=UTF-8'}})
      .then(
        response => {
          if(response.data.intEstatus == 0){
            this.$vs.notify({
              title:`Guardado exitosamente`,
              position:'top-right'
            })
            this.registraAseguradora()
            this.dameCaso()
            this.dameSolicitante()
            if (this.AccreditedidV3) { //si el solicitante cuenta con pedidos en v3 entonces actualiza inf en liga cliente.
              this.generaInformacionParaV3(); 
            }
          }else {
            this.$vs.notify({
              title:'Ocurrio un error en RegistraAdicionalesSolicitud',
              text:response.data.strError,
              color:'danger',
              position:'top-right'
            })
          }
        }
      ).catch(function (error) {
        self.$vs.notify({
          title:'Ocurrio un error de sistema',
          text:error,
          color:'danger',
          position:'top-right'
        })
      })
    },
    generaInformacionParaV3(){
			let self=this
              var objRequestDameInfo = {
                strFolioCaso: this.id,
                strApiKey: this.key,
                strMetodo: 'generaInformacionParaV3',
                objSolicitante:
                {
                    EMail: this.email
                },
                objCaso: {
                Id: this.IdCaso,
                }
              }
              this.$axios.post('/',objRequestDameInfo,{headers : {'Content-Type':'application/x-www-form-urlencoded; charset=UTF-8'}})
              .then(
                response => {
                  if(response.data.intEstatus == 0){
                    this.solicitanteV3 = response.data.objContenido
                    this.updateInfoEnV3()
                  }else {
                    this.$vs.notify({
                      title:'Ocurrio un error en generaInformacionParaV3',
                      text:response.data.strError,
                      color:'danger',
                      position:'top-right'
                    })
                  }
                }
              ).catch(function (error) {
                self.$vs.notify({
                  title:'Ocurrio un error de sistema',
                  text:error,
                  color:'danger',
                  position:'top-right'
                })
              })
		  },
      async updateInfoEnV3(){
        await this.getTokenSisec();
        
        let self=this
          
        var mostrar = ""
        let objRequestUpdateInfo =
        {
        IsQuickQuote:false,
        AccreditedModel: {
          Name: this.solicitanteV3.Nombres,
          SName: this.solicitanteV3.SegundoNombre,
          LastName: this.solicitanteV3.ApellidoPaterno,
          SecondLastName: this.solicitanteV3.ApellidoMaterno,
          PersonalMail: this.email,
          PhoneHome: this.solicitanteV3.TelefonoCasa,
          CellPhone: this.solicitanteV3.TelefonoCelular,
          DateBirtday: this.solicitanteV3.FechaNacimiento,
          Gender: this.solicitanteV3.Genero,
          Nationality: this.solicitanteV3.Nacionalidad,
          CURP: this.solicitanteV3.CURP,
          RFC: this.solicitanteV3.RFC,
          Weight: this.solicitanteV3.Peso,
          Height: this.solicitanteV3.Estatura,
          NSS: this.solicitanteV3.Nss,
          StudyGrade: this.solicitanteV3.GradoEstudios,
          CivilStatus: this.solicitanteV3.EstadoCivil,
          EconomicDependents: this.solicitanteV3.DependientesEconomicos,
          NumberOfChildren: this.solicitanteV3.NumeroHijos,
          ChildrensAge: this.solicitanteV3.EdadHijos,
          Cocredited: this.solicitanteV3.Coacreditado,
          //domicilio
          TypeHousing: this.solicitanteV3.TipoInmueble,
          ResidenceTimeHome: this.solicitanteV3.TiempoResidenciaActual,
          ResidenceTimeTown: this.solicitanteV3.TiempoResidenciaPoblacion,
          CP: this.solicitanteV3.CodigoPostal,
          //Bienes inmuebles
          NumberOfCars: this.solicitanteV3.NumeroAutos,				
          //Ingresos
          Activity: this.solicitanteV3.Sector,
          TypeCompany: this.solicitanteV3.TipoEmpleo,
          CompanyName: this.solicitanteV3.Empresa,
          CompanyAddress: this.solicitanteV3.DomicilioEmpresa,
          MonetaryIncome: this.solicitanteV3.Ingresos,
          Position: this.solicitanteV3.Puesto,
          DateBeginWork: this.solicitanteV3.FechaInicio,

          Age: this.solicitanteV3.Edad,		
        },
        ReferencesModel: {
          Name: this.solicitanteV3.NombreReferencia,
          SName: null,
          LastName: this.solicitanteV3.ApellidoPaternoRef,
          SecondLastName: this.solicitanteV3.ApellidoMaternoRef,
          Phone: this.solicitanteV3.TelCelularRef,
          Relationship: this.solicitanteV3.ParentescoRef,
          TimeRelationship: this.solicitanteV3.AnosConocerloRef
        },
        CreditRequestModel: {
          TypeCredit: null,
          HomeValue: null,
          CreditAmount: null,
          Plazo: null
        },
        BrokerEmployeeModel: {
          Id: this.SocGuid,
          BrokerId: this.BrokerId,
            //para pruebas comentar las dos de arriba, terminando descomentar arriba y commentar abajo
            //Id: "61711b5c-948c-4928-94f0-158b96b58da0",
            //BrokerId:"ca1b7cbd-f2a5-4507-9f03-4c167dcb2663",
          EmployeeName: null,
          EmployeeEmail: null,
          Origin: "Cotizador soc"
          
        },
        MaterialGoodsModel:{
          Properties:{
            NumberProperties: this.solicitanteV3.NumeroInmuebles,
            ApproximateValue: this.solicitanteV3.ValorAproximado,
            EncumberedValue: this.solicitanteV3.ValorGrabado,
          },
          CarOne:{
            ApproximateValueCar: this.solicitanteV3.ValorAuto1,
            MarkCar: this.solicitanteV3.MarcaAuto1,
            ModelCar: this.solicitanteV3.ModeloAuto1,
            YearCar: this.solicitanteV3.AnioAuto1,
          },
          CarTwo:{
            ApproximateValueCar: this.solicitanteV3.ValorAuto2,
            MarkCar: this.solicitanteV3.MarcaAuto2,
            ModelCar: this.solicitanteV3.ModeloAuto2,
            YearCar: this.solicitanteV3.AnioAuto2
          }
        }

        
        }   
        this.$axios.post('https://apivalidacionriesgos.sisec.mx/api/External/CreateAccredited',objRequestUpdateInfo,{headers : this.header})
          .then(
          response => {
                  
          }
          ).catch(function (error) {
            if(error.response.data.Message == "The CellPhone already exists"){
            mostrar = "El telefono ya se encuentra registrado intente con otro"
            }else if(error.response.data.Message == "The email already exists"){
            mostrar = "El correo elecronico ya se encuentra registrado intente con otro"
            }else{
            mostrar = error
            }
            
            self.$vs.notify({
              title:'Ocurrio un error en SISEC',
              text:mostrar,
              color:'danger',
              position:'top-right'
            })
          })

      },
      DameCredenciales(){
        let self=this
        let objRequestDameCredenciales = {
          strApiKey: this.key,
          strMetodo: 'DameCredenciales',
          objBroker: {
          ApiKey: this.key
          }
        }
        this.$axios.post('/',objRequestDameCredenciales,{headers : {'Content-Type':'application/x-www-form-urlencoded; charset=UTF-8'}})
        .then(
          response => {
          this.SocGuid = response.data.objContenido.SocGuid
          this.BrokerId = response.data.objContenido.BrokerId

          //this.SocGuid = 'f5b58eb5-4f2e-49c1-ae90-0fcab572c879';//solo pruebas
          //this.BrokerId = 'f5b58eb5-4f2e-49c1-ae90-0fcab572c879';
          }
        ).catch(function (error) {
          self.$vs.notify({
          title:'Ocurrio un error de sistema',
          text:error,
          color:'danger',
          position:'top-right'
          })
        })
      },
    dameCaso(){
      let self=this
      var objRequestDameCaso = {
        strApiKey: this.key,
        strMetodo: 'DameCaso',
        objCaso: {
          Id: this.id
        }
      }
      this.$axios.post('/',objRequestDameCaso,{headers : {'Content-Type':'application/x-www-form-urlencoded; charset=UTF-8'}})
      .then(
        response => {
          if(response.data.intEstatus == 0){
            this.email= response.data.objContenido.Solicitante
            this.IdCaso = response.data.objContenido.Id
            const dataCaso = response.data.objContenido
            const bancos = dataCaso.ComparaBancos

            this.SeguroMontoCiti= dataCaso.SeguroMontoCiti
            this.SeguroInsolutoCiti= dataCaso.SeguroInsolutoCiti
            this.SeguroDanosCiti= dataCaso.SeguroDanosCiti

            

            if(response.data.objContenido.NombreAdicional!=null){
                this.BanderaActualizacion1=true
                this.adicionales.nombreAsesor=  response.data.objContenido.NombreAdicional
              
            }

             if(response.data.objContenido.RfcAdicional!=null){
                this.BanderaActualizacion2=true
                this.adicionales.rfcAsesor=  response.data.objContenido.RfcAdicional
            }


            
            

            // CITIBANAMEX
            const isCitibanamex = bancos.includes('2')
            if ( isCitibanamex ) {

              this.tipoSeguro = dataCaso.TipoSeguro
              this.seguroDanos = dataCaso.SeguroDanos
              this.isActiveContentCiti = true

              this.SeguroMontoCiti= dataCaso.SeguroMontoCiti
              this.SeguroInsolutoCiti= dataCaso.SeguroInsolutoCiti
              this.SeguroMontoCiti= dataCaso.SeguroMontoCiti

            }

            let caso=response.data.objContenido
            //empresa
            this.adicionales.empresa.rfc=caso.RfcEmpresaPropietario
            this.adicionales.empresa.representanteLegalRfc=caso.RfcConyugePropietario
            this.adicionales.empresa.nombre=caso.NombreEmpresaPropietario
            this.adicionales.empresa.representanteLegal=caso.NombreConyugePropietario
            this.adicionales.empresa.apellidoPaternoRepresentante=caso.ApellidoPaternoRepresentante
            this.adicionales.empresa.apellidoMaternoRepresentante=caso.ApellidoMaternoRepresentante
            //Autos
            this.adicionales.vehiculos.numero=caso.AutosPropiosNumero
            this.adicionales.vehiculos.marca=caso.AutosPropiosMarca
            this.adicionales.vehiculos.modelo=caso.AutosPropiosModelo

            if (caso.AutosPropiosAno==null) 
              this.adicionales.vehiculos.anio=''             
            else
              this.adicionales.vehiculos.anio=caso.AutosPropiosAno

            this.adicionales.vehiculos.pago= caso.AutosPropiosPago

            this.adicionales.vehiculos.marca2=caso.AutosPropios2Marca
            this.adicionales.vehiculos.modelo2=caso.AutosPropios2Modelo

            if (caso.AutosPropios2Ano==null) 
              this.adicionales.vehiculos.anio2=''             
            else
              this.adicionales.vehiculos.anio2=caso.AutosPropios2Ano

            this.adicionales.vehiculos.pago2= caso.AutosPropios2Pago

            if (caso.AutosPropiosValor) {
              this.adicionales.vehiculos.valor=(caso.AutosPropiosValor)//.toString().replace(/\D/g, "").replace(/\B(?=(\d{3})+(?!\d))/g, ",")
              this.adicionales.vehiculos.valor=this.formateaValoresConsultados(this.adicionales.vehiculos.valor.toFixed(2))
            }
            if (caso.AutosPropios2Valor) {
              this.adicionales.vehiculos.valor2=(caso.AutosPropios2Valor)//.toString().replace(/\D/g, "").replace(/\B(?=(\d{3})+(?!\d))/g, ",")
              this.adicionales.vehiculos.valor2=this.formateaValoresConsultados(this.adicionales.vehiculos.valor2.toFixed(2))
            }
            //Propiedades
            this.adicionales.propiedades.numero=caso.InmueblesPropiosNumero
            if (caso.InmueblesPropiosTipo==null) 
              this.adicionales.propiedades.tipoInmueble=''             
            else 
              this.adicionales.propiedades.tipoInmueble=caso.InmueblesPropiosTipo

            if (caso.InmueblesPropiosValor) {
              this.adicionales.propiedades.valor=(caso.InmueblesPropiosValor)//.toString().replace(/\D/g, "").replace(/\B(?=(\d{3})+(?!\d))/g, ",")
              this.adicionales.propiedades.valor=this.formateaValoresConsultados(this.adicionales.propiedades.valor.toFixed(2))
            }
            if (caso.InmueblesPropiosGravado) {
              this.adicionales.propiedades.valorGravado=(caso.InmueblesPropiosGravado)//.toString().replace(/\D/g, "").replace(/\B(?=(\d{3})+(?!\d))/g, ",")
              this.adicionales.propiedades.valorGravado=this.formateaValoresConsultados(this.adicionales.propiedades.valorGravado.toFixed(2))
            }
            //Otros
            this.adicionales.otros.consultarEstadoCuentaOnline=caso.ConsultaEdoCuenta
            this.adicionales.otros.ofertaVinculante=caso.OfertaVinculante
            this.adicionales.otros.recibirPublicidadMercadeo=caso.PublicidadMercadeo
            this.adicionales.otros.autorizarTratamientoDatosAfirme=caso.DatosPersonalesTratados
            this.adicionales.otros.tramitarTarjetaCreditoScotiaBank=caso.TramitarTdc
            this.adicionales.otros.comisionPorAperturaPrepagoNoScotiaBank=caso.ComisionApertura
            this.adicionales.otros.autorizarImpresionNumeroCreditoEstadoCuentaBanorte=caso.ImpresionNumCredito
            this.adicionales.otros.autorizarImpresionNombreEstadoCuentaBanorte=caso.ImpresionNombre
            this.adicionales.otros.autorizarContactoHorarioLaboralBanorte=caso.ContactadoHorarioLaboral
            this.adicionales.otros.contratarPaqueteIntegralBanorte=caso.ContrataPaqueteIntegral
            if (caso.TipoLugarAtencion==null) 
              this.adicionales.otros.tipoLugarAtencion=''             
            else 
              this.adicionales.otros.tipoLugarAtencion=caso.TipoLugarAtencion

            if (caso.ReferenciaFamiliarFinanciera==null) 
              this.adicionales.otros.referenciaFamiliarFinanciera=''             
            else 
              this.adicionales.otros.referenciaFamiliarFinanciera=caso.ReferenciaFamiliarFinanciera
              
            //InmuebleGarantia
            this.adicionales.inmuebleGarantia.calle=caso.CalleInmueble
            this.adicionales.inmuebleGarantia.numero=caso.NumeroExteriorInmueble
            this.adicionales.inmuebleGarantia.colonia=caso.ColoniaInmueble
            this.adicionales.inmuebleGarantia.municipio=caso.MunicipioInmueble
            this.adicionales.inmuebleGarantia.ciudad=caso.CiudadInmueble
            if (caso.EstadoInmueble==null) 
              this.adicionales.inmuebleGarantia.estado=''             
            else
              this.adicionales.inmuebleGarantia.estado=caso.EstadoInmueble
            this.adicionales.inmuebleGarantia.pais=caso.PaisInmueble
            this.adicionales.inmuebleGarantia.codigo=caso.CodigoPostalInmueble
            this.adicionales.inmuebleGarantia.lote=caso.LoteInmueble
            this.adicionales.inmuebleGarantia.manzana=caso.ManzanaInmueble
            this.adicionales.inmuebleGarantia.superficieTerreno=caso.SuperficieInmueble
            this.adicionales.inmuebleGarantia.areaConstruccion=caso.AreaConstruccionInmueble
            this.adicionales.inmuebleGarantia.descripcion=caso.DescripcionInmueble
            if (caso.ValorEstimadoInmueble) {
              this.adicionales.inmuebleGarantia.valorEstimado=(caso.ValorEstimadoInmueble).toString().replace(/\D/g, "").replace(/\B(?=(\d{3})+(?!\d))/g, ",")
            }
            //propietarioInmueble
            this.adicionales.propietarioInmueble.nombre=caso.NombrePropietario
            this.adicionales.propietarioInmueble.apellidoPaterno=caso.ApellidoPaternoPropietario
            this.adicionales.propietarioInmueble.apellidoMaterno=caso.ApellidoMaternoPropietario
            this.adicionales.propietarioInmueble.calle=caso.CallePropietario
            this.adicionales.propietarioInmueble.numeroExterior=caso.NumeroExteriorPropietario
            this.adicionales.propietarioInmueble.colonia=caso.ColoniaPropietario
            this.adicionales.propietarioInmueble.municipio=caso.MunicipioPropietario
            this.adicionales.propietarioInmueble.ciudad=caso.CiudadPropietario

            if (caso.EstadoPropietario==null) 
              this.adicionales.propietarioInmueble.estado=''             
            else 
              this.adicionales.propietarioInmueble.estado=caso.EstadoPropietario

            this.adicionales.propietarioInmueble.pais=caso.PaisPropietario
            this.adicionales.propietarioInmueble.codigoPostal=caso.CodigoPostalPropietario
            this.adicionales.propietarioInmueble.telefono=caso.TelefonoPropietario
            this.adicionales.propietarioInmueble.rfc=caso.RfcPropietario
            this.adicionales.propietarioInmueble.estadoCivil=caso.EstadoCivilPropietario

            //otros 2
            this.adicionales.lugarSolicitud=caso.LugarSolicitud

           
            this.adicionales.FechaSolicitud= caso.FechaSolicitud

            if (caso.UsoInmueble==null) 
              this.adicionales.usoInmueble=''             
            else 
              this.adicionales.usoInmueble=caso.UsoInmueble
            
            if (caso.TipoInmueble==null) 
              this.adicionales.tipoInmueble=''             
            else
              this.adicionales.tipoInmueble=caso.TipoInmueble

            this.adicionales.seguro=caso.SeguroBancario
            if (caso.FormaEnganche==null) 
              this.adicionales.formaEnganche=''             
            else
              this.adicionales.formaEnganche=caso.FormaEnganche

            this.adicionales.seguros=caso.SeguroBancario
            if(response.data.objContenido.EnfermedadActivosSol)
              this.validaswitch(response.data.objContenido.EnfermedadActivosSol)

            this.dameSolicitante()
          }else {
            this.$vs.notify({
              title:'Ocurrio un error en DameCaso',
              text:response.data.strError,
              color:'danger',
              position:'top-right'
            })
          }
        }
      ).catch(function (error) {
        self.$vs.notify({
          title:'Ocurrio un error de sistema',
          text:error,
          color:'danger',
          position:'top-right'
        })
      })
    },
    dameSolicitante(){
      let self=this
      var objRequestDameSolicitante = {
        strFolioCaso: this.id,
        strApiKey: this.key,
        strMetodo: 'DameSolicitante',
        objSolicitante: {
          EMail: this.email
        }
      }
      this.$axios.post('/',objRequestDameSolicitante,{headers : {'Content-Type':'application/x-www-form-urlencoded; charset=UTF-8'}})
      .then(
        response => {
          if(response.data.intEstatus == 0){
            this.solicitante= response.data.objContenido
            this.adicionales.nss= this.solicitante.Nss
            this.adicionales.issste= this.solicitante.Issste
            this.adicionales.fiel= this.solicitante.Fiel
            if (response.data.objContenido.Peso != null) {
              this.peso=response.data.objContenido.Peso  
            }
            if (response.data.objContenido.Estatura != null) {
              this.estatura=response.data.objContenido.Estatura
            }
            if (response.data.objContenido.Peso != null) {
              this.peso=response.data.objContenido.Peso  
            }
            if (response.data.objContenido.Estatura != null) {
              this.estatura=response.data.objContenido.Estatura
            }
            if(this.solicitante.CompaniaCelular){
            this.adicionales.companiaCelular=this.solicitante.CompaniaCelular.replace(/&amp;/g, "&")
            }
            this.adicionales.numeroIfe=this.solicitante.NumeroIdentificacion
            // this.adicionales.municipioNacimiento=this.solicitante.MunicipioNacimiento
            // this.adicionales.paisNacimiento=this.solicitante.PaisNacimiento
            this.adicionales.telefonoOficina=this.solicitante.TelefonoOficina
            this.adicionales.telefonoFijo=this.solicitante.TelefonoCasa
            this.adicionales.otroNombre=this.solicitante.OtroNombreAnterior

            // if (this.solicitante.EstadoNacimiento==null) 
            //   this.adicionales.estadoNacimiento=''             
            // else
            //   this.adicionales.estadoNacimiento=this.solicitante.EstadoNacimiento

            this.adicionales.antiguedadDomicilioAnterior=this.solicitante.AntiguedadDomicilioAnterior
            this.adicionales.antiguedadCiudad=this.solicitante.AntiguedadEnCiudad ? this.solicitante.AntiguedadEnCiudad : 0;
            this.adicionales.antiguedadInmuebleAdquirir=this.solicitante.AntiguedadVivienda
            this.adicionales.institucionEstudios=this.solicitante.EscolaridadInstitucion

            // 2023-09-19
            this.adicionales.antiguedadCiudadMeses=this.solicitante.AntiguedadEnCiudadMeses ? this.solicitante.AntiguedadEnCiudadMeses : 0;
            this.adicionales.tiempoOcupandoDomicilioActualMeses=this.solicitante.TiempoViviendoCiudadMeses ? this.solicitante.TiempoViviendoCiudadMeses : 0;


            if (this.solicitante.Escolaridad==null) 
              this.adicionales.gradoEstudios=''             
            else
              this.adicionales.gradoEstudios=this.solicitante.Escolaridad

            if (this.solicitante.Identificacion==null) 
              this.adicionales.identificacionPresenta=''             
            else
              this.adicionales.identificacionPresenta= this.solicitante.Identificacion

            this.adicionales.tiempoOcupandoDomicilioActual= this.solicitante.TiempoViviendoCiudad ? this.solicitante.TiempoViviendoCiudad : 0;
            this.adicionales.vigencia= this.solicitante.VigenciaIdentificacion

            if (this.solicitante.TipoOcupacion==null) 
              this.adicionales.titularidadDomicilio=''             
            else
              this.adicionales.titularidadDomicilio= this.solicitante.TipoOcupacion

            this.adicionales.calleExtranjero=this.solicitante.CalleExtranjero
            this.adicionales.numeroExteriorExtranjero= this.solicitante.NumeroExteriorExtranjero
            this.adicionales.numeroInteriorExtranjero= this.solicitante.NumeroInteriorExtranjero
            this.adicionales.coloniaExtranjero= this.solicitante.ColoniaExtranjero
            this.adicionales.estadoExtranjero=this.solicitante.EstadoExtranjero
            this.adicionales.paisExtranjero=this.solicitante.PaisExtranjero
            this.adicionales.codigoPostalExtranjero=this.solicitante.CodigoPostalExtranjero
            this.adicionales.NacionalidadDetalle=this.solicitante.NacionalidadDetalle
            // this.adicionales.PaisRecidencia=this.solicitante.PaisRecidencia
            if (this.solicitante.GastosMensuales) {
              this.adicionales.totalGastosMensuales=(this.solicitante.GastosMensuales).toString().replace(/\D/g, "").replace(/\B(?=(\d{3})+(?!\d))/g, ",")
            }
            if (this.solicitante.PensionAlimenticiaMonto) {
              this.adicionales.montoPension=(this.solicitante.PensionAlimenticiaMonto).toString().replace(/\D/g, "").replace(/\B(?=(\d{3})+(?!\d))/g, ",")
            }

            for (var i = 0; i < response.data.objContenido.DatosAseguradora.length; i++) {
                if (!this.padecimientos.includes(response.data.objContenido.DatosAseguradora[i].Id)) {
                  this.padecimientos.push(response.data.objContenido.DatosAseguradora[i].Id)
                }                                
              }
              if (this.solicitante.AccreditedIdV3 !=null && this.solicitante.AccreditedIdV3 != '') {
                this.AccreditedidV3 = true;
              }

          }else {
            this.$vs.notify({
              title:'Ocurrio un error en DameSolicitante',
              text:response.data.strError,
              color:'danger',
              position:'top-right'
            })
          }
        }
      ).catch(function (error) {
        self.$vs.notify({
          title:'Ocurrio un error de sistema',
          text:error,
          color:'danger',
          position:'top-right'
        })
      })
    },
    getFormasEnganche(){
      let self=this
      var objRequestListaFormasEnganche = {
        strApiKey: this.key,
        strMetodo: 'ListaFormasEnganche',
      }
      this.$axios.post('/',objRequestListaFormasEnganche,{headers : {'Content-Type':'application/x-www-form-urlencoded; charset=UTF-8'}})
      .then(
        response => {
          if(response.data.intEstatus == 0){
            this.formasEnganche=response.data.objContenido
          }else {
            this.$vs.notify({
              title:'Ocurrio un error en ListaFormasEnganche',
              text:response.data.strError,
              color:'danger',
              position:'top-right'
            })
          }
        }
      ).catch(function (error) {
        self.$vs.notify({
          title:'Ocurrio un error de sistema',
          text:error,
          color:'danger',
          position:'top-right'
        })
      })
    },
    getUsoInmueble(){
      let self=this
      var objRequestListaUsoInmuebles = {
        strApiKey: this.key,
        strMetodo: 'ListaUsoInmuebles',
      }
      this.$axios.post('/',objRequestListaUsoInmuebles,{headers : {'Content-Type':'application/x-www-form-urlencoded; charset=UTF-8'}})
      .then(
        response => {
          if(response.data.intEstatus == 0){
            this.usosInmuebles=response.data.objContenido
          }else {
            this.$vs.notify({
              title:'Ocurrio un error en ListaUsoInmuebles',
              text:response.data.strError,
              color:'danger',
              position:'top-right'
            })
          }
        }
      ).catch(function (error) {
        self.$vs.notify({
          title:'Ocurrio un error de sistema',
          text:error,
          color:'danger',
          position:'top-right'
        })
      })
    },
    getListaDomicilioOcupaciones(){
      let self=this
      var objRequestListaDomicilioOcupaciones = {
        strApiKey: this.key,
        strMetodo: 'ListaDomicilioOcupaciones',
      }
      this.$axios.post('/',objRequestListaDomicilioOcupaciones,{headers : {'Content-Type':'application/x-www-form-urlencoded; charset=UTF-8'}})
      .then(
        response => {
          if(response.data.intEstatus == 0){
            this.domicilioOcupaciones=response.data.objContenido
          }else {
            this.$vs.notify({
              title:'Ocurrio un error en ListaDomicilioOcupaciones',
              text:response.data.strError,
              color:'danger',
              position:'top-right'
            })
          }
        }
      ).catch(function (error) {
        self.$vs.notify({
          title:'Ocurrio un error de sistema',
          text:error,
          color:'danger',
          position:'top-right'
        })
      })
    },
    getGradosEstudio(){
      let self=this
      var objRequestListaEscolaridades = {
        strApiKey: this.key,
        strMetodo: 'ListaEscolaridades',
      }
      this.$axios.post('/',objRequestListaEscolaridades,{headers : {'Content-Type':'application/x-www-form-urlencoded; charset=UTF-8'}})
      .then(
        response => {
          if(response.data.intEstatus == 0){
            this.gradosEstudio=response.data.objContenido
          }else {
            this.$vs.notify({
              title:'Ocurrio un error en ListaEscolaridades',
              text:response.data.strError,
              color:'danger',
              position:'top-right'
            })
          }
        }
      ).catch(function (error) {
        self.$vs.notify({
          title:'Ocurrio un error de sistema',
          text:error,
          color:'danger',
          position:'top-right'
        })
      })
    },
    getEstados(){
      let self=this
      var objRequestListaEstados = {
        strApiKey: this.key,
        strMetodo: 'ListaEstados',
      }
      this.$axios.post('/',objRequestListaEstados,{headers : {'Content-Type':'application/x-www-form-urlencoded; charset=UTF-8'}})
      .then(
        response => {
          if(response.data.intEstatus == 0){
            this.estados=response.data.objContenido
          }else {
            this.$vs.notify({
              title:'Ocurrio un error en ListaEstados',
              text:response.data.strError,
              color:'danger',
              position:'top-right'
            })
          }
        }
      ).catch(function (error) {
        self.$vs.notify({
          title:'Ocurrio un error de sistema',
          text:error,
          color:'danger',
          position:'top-right'
        })
      })
    },
    getTiposInmuebles(){
      let self=this
      var objRequestListaTipoInmuebles = {
        strApiKey: this.key,
        strMetodo: 'ListaTipoInmuebles',
      }
      this.$axios.post('/',objRequestListaTipoInmuebles,{headers : {'Content-Type':'application/x-www-form-urlencoded; charset=UTF-8'}})
      .then(
        response => {
          if(response.data.intEstatus == 0){
            this.tiposInmuebles=response.data.objContenido
          }else {
            this.$vs.notify({
              title:'Ocurrio un error en ListaTipoInmuebles',
              text:response.data.strError,
              color:'danger',
              position:'top-right'
            })
          }
        }
      ).catch(function (error) {
        self.$vs.notify({
          title:'Ocurrio un error de sistema',
          text:error,
          color:'danger',
          position:'top-right'
        })
      })
    },
    soloLetras: function (event) {
			var regex = new RegExp('^[áéíóúüña-zÑÁÉÍÓÚA-Z ]+$')
			var key = String.fromCharCode(!event.charCode ? event.which : event.charCode)
			if (!regex.test(key)) {
				event.preventDefault()
				return false
			}
		},
    getIdentificaciones(){
      let self=this
      var objRequestListaTipoIdentificaciones = {
        strApiKey: this.key,
        strMetodo: 'ListaTipoIdentificaciones',
      }
      this.$axios.post('/',objRequestListaTipoIdentificaciones,{headers : {'Content-Type':'application/x-www-form-urlencoded; charset=UTF-8'}})
      .then(
        response => {
          if(response.data.intEstatus == 0){

            let identificacionList = response.data.objContenido;
            identificacionList = identificacionList.filter(identi => identi.Id != 7);
            this.identificaciones = identificacionList;

            // 2023-05-04 Mejora en identificacion
            // this.identificaciones=response.data.objContenido
          }else {
            this.$vs.notify({
              title:'Ocurrio un error en ListaTipoIdentificaciones',
              text:response.data.strError,
              color:'danger',
              position:'top-right'
            })
          }
        }
      ).catch(function (error) {
        self.$vs.notify({
          title:'Ocurrio un error de sistema',
          text:error,
          color:'danger',
          position:'top-right'
        })
      })
    },
    getEstadosCiviles(){
      let self=this
      var objRequestListaEstadosCiviles = {
        strApiKey: this.key,
        strMetodo: 'ListaEstadosCiviles',
      }
      this.$axios.post('/',objRequestListaEstadosCiviles,{headers : {'Content-Type':'application/x-www-form-urlencoded; charset=UTF-8'}})
      .then(
        response => {
          if(response.data.intEstatus == 0){
            this.estadosCiviles=response.data.objContenido
          }else {
            this.$vs.notify({
              title:'Ocurrio un error en ListaEstadosCiviles',
              text:response.data.strError,
              color:'danger',
              position:'top-right'
            })
          }
        }
      ).catch(function (error) {
        self.$vs.notify({
          title:'Ocurrio un error de sistema',
          text:error,
          color:'danger',
          position:'top-right'
        })
      })
    },
    getListaTipoLugarAtencion(){
      let self=this
      var objRequestListaEstadosCiviles = {
        strApiKey: this.key,
        strMetodo: 'ListaTipoLugarAtencion',
      }
      this.$axios.post('/',objRequestListaEstadosCiviles,{headers : {'Content-Type':'application/x-www-form-urlencoded; charset=UTF-8'}})
      .then(
        response => {
          if(response.data.intEstatus == 0){
            this.tiposLugaresAtencion=response.data.objContenido
          }else {
            this.$vs.notify({
              title:'Ocurrio un error en ListaTipoLugarAtencion',
              text:response.data.strError,
              color:'danger',
              position:'top-right'
            })
          }
        }
      ).catch(function (error) {
        self.$vs.notify({
          title:'Ocurrio un error de sistema',
          text:error,
          color:'danger',
          position:'top-right'
        })
      })
    },
    getParentescos(){
      let self= this
      var objRequestListaCasoEstatus = {
        strApiKey: this.key,
        strMetodo: 'ListaParentescos',
      }
      this.$axios.post('/',objRequestListaCasoEstatus,{headers : {'Content-Type':'application/x-www-form-urlencoded; charset=UTF-8'}})
      .then(
        response => {
          if(response.data.intEstatus == 0){
            this.parentescos=response.data.objContenido
          }else {
            this.$vs.notify({
              title:'Ocurrio un error en ListaParentescos',
              text:response.data.strError,
              color:'danger',
              position:'top-right'
            })
          }
        }
      ).catch(function (error) {
        self.$vs.notify({
          title:'Ocurrio un error de sistema',
          text:error,
          color:'danger',
          position:'top-right'
        })
      })
    },
    getApikey(){
      if(!JSON.parse(localStorage.getItem('userInfo')).ApiKey){
        this.$router.push('/login');
      }else {
        this.key=JSON.parse(localStorage.getItem('userInfo')).ApiKey;
      }
    },
    getMisDatos(){
       let self= this
      var objRequestMisDatos= {
        strApiKey: this.key,
        strMetodo: 'MisDatos',
      }
      this.$axios.post('/',objRequestMisDatos,{headers : {'Content-Type':'application/x-www-form-urlencoded; charset=UTF-8'}})
      .then(
        response => {
          if(response.data.intEstatus == 0){
            const Datos =response.data.objContenido

             
              if( this.BanderaActualizacion1==false){
           
                  this.adicionales.nombreAsesor=  response.data.objContenido.Nombre     
              }

               if( this.BanderaActualizacion2==false){
                  this.adicionales.rfcAsesor=  response.data.objContenido.Rfc
              }
     
 
          }else {
            this.$vs.notify({
              title:'Ocurrio un error en MisDatos',
              text:response.data.strError,
              color:'danger',
              position:'top-right'
            })
          }
        }
      ).catch(function (error) {
        self.$vs.notify({
          title:'Ocurrio un error de sistema',
          text:error,
          color:'danger',
          position:'top-right'
        })
      })

    },
    getAños(){
      let current=new Date().getFullYear()+1
      for (var i = 0; i < 31; i++) {
        this.años.push({ Nombre: current-i,  Id: current-i })
      }
    },
    isNumber: function(evt) {
      evt = (evt) ? evt : window.event;
      var charCode = (evt.which) ? evt.which : evt.keyCode;
      if ((charCode > 31 && (charCode < 48 || charCode > 57)) && charCode !== 46) {
        evt.preventDefault();
      } else {
        return true;
      }
    },

    
    cambia(val){
      
      if(val==1){
        if(!this.SolswitchAfecMedicas){
          $('#Afecciones').show()
        }else{
          $('#Afecciones').hide()
        }
      }
      if(val==2){
        if(!this.SolswitchProMedicos){
          $('#Procedimientos').show()
        }else{
          $('#Procedimientos').hide()
        }
      }
      if(val==3){
        if(!this.SolswitchHistorial){
          $('#Historial').show()
        }else{
          $('#Historial').hide()
        }
      }
      if(val==4){
        if(!this.SolswitchHabitos){
          $('#Habitos').show()
        }else{
          $('#Habitos').hide()
        }
      }
      if(val==5){
        if(!this.SolswitchAcLaboral){
          $('#AcLaboral').show()
        }else{
          $('#AcLaboral').hide()
        }
      }    
      if(val==6){
        if(!this.SolswitchOtro){
          $('#Otro').show()
        }else{
          $('#Otro').hide()
        }
      } 
       if(val==7){
        if(!this.SolswitchCiti){
          $('#Citi').show()
        }else{
          $('#Citi').hide()
        }
      } 
      
    },
    listaEnfermedades(){
      let self= this
      var objRequestListaEnfermedades = {
        strApiKey: this.key,
        strMetodo: 'listaConceptosAseguradora',
      }
      this.$axios.post('/',objRequestListaEnfermedades,{headers : {'Content-Type':'application/x-www-form-urlencoded; charset=UTF-8'}})
      .then(
        response => {
          if(response.data.intEstatus == 0){

            this.padecimientosBase = response.data.objContenido
            var cuenta = this.padecimientosBase.length
            var a = 0
            var b = 0
            var c = 0
            var d = 0
            var f = 0
            var g = 0

            this.padecimientosBase.sort(function(a, b) {
              return a.Id - b.Id;
            })

            for (var i = 0; i < cuenta; i++) {
              if(this.padecimientosBase[i].Id==this.Afecciones[a]){ 
                a++
                this.padecimientosOpcionesAfecciones.push({ Id: this.padecimientosBase[i].Id, Nombre: this.padecimientosBase[i].Nombre })
              }
              if(this.padecimientosBase[i].Id==this.Procedimientos[b]){ 
                b++
                this.padecimientosOpcionesProcedimientos.push({ Id: this.padecimientosBase[i].Id, Nombre: this.padecimientosBase[i].Nombre })
              }
              if(this.padecimientosBase[i].Id==this.Historial[c]){ 
                c++
                this.padecimientosOpcionesHistorial.push({ Id: this.padecimientosBase[i].Id, Nombre: this.padecimientosBase[i].Nombre })
              }
              if(this.padecimientosBase[i].Id==this.Habitos[d]){ 
                d++
                this.padecimientosOpcionesHabitos.push({ Id: this.padecimientosBase[i].Id, Nombre: this.padecimientosBase[i].Nombre })
              }
              if(this.padecimientosBase[i].Id==this.AcLaboral[f]){ 
                f++
                this.padecimientosOpcionesAcLaboral.push({ Id: this.padecimientosBase[i].Id, Nombre: this.padecimientosBase[i].Nombre })
              }
              if(this.padecimientosBase[i].Id==this.Otro[g]){ 
                g++
                this.padecimientosOpcionesOtro.push({ Id: this.padecimientosBase[i].Id, Nombre: this.padecimientosBase[i].Nombre })
              }
            }

            this.padecimientosOpcionesAfecciones.sort(function(a, b) {
              if (a.Nombre.toLowerCase() < b.Nombre.toLowerCase()) {
                return -1;
              }
            })
            this.padecimientosOpcionesProcedimientos.sort(function(a, b) {
              if (a.Nombre.toLowerCase() < b.Nombre.toLowerCase()) {
                return -1;
              }
            })
            this.padecimientosOpcionesHistorial.sort(function(a, b) {
              if (a.Nombre.toLowerCase() < b.Nombre.toLowerCase()) {
                return -1;
              }
            })
            this.padecimientosOpcionesHabitos.sort(function(a, b) {
              if (a.Nombre.toLowerCase() < b.Nombre.toLowerCase()) {
                return -1;
              }
            })
            this.padecimientosOpcionesAcLaboral.sort(function(a, b) {
              if (a.Nombre.toLowerCase() < b.Nombre.toLowerCase()) {
                return -1;
              }
            })
            this.padecimientosOpcionesOtro.sort(function(a, b) {
              if (a.Nombre.toLowerCase() < b.Nombre.toLowerCase()) {
                return -1;
              }
            })

          }else {
            this.$vs.notify({
              title:'Ocurrio un error en listaConceptosAseguradora',
              text:response.data.strError,
              color:'danger',
              position:'top-right'
            })
          }
        }
      ).catch(function (error) {
        self.$vs.notify({
          title:'Ocurrio un error de sistema',
          text:error,
          color:'danger',
          position:'top-right'
        })
      })
    },
    registraAseguradora(){
      let self= this
      let enfermAct=[]
      let enfermedades=[]
      
      for (var i = 0; i < this.padecimientos.length; i++) {
        enfermedades.push({"Concepto":this.padecimientos[i]})
      }
      
      if(this.SolswitchAfecMedicas)
        enfermAct.push(1)
        
      if(this.SolswitchProMedicos)
        enfermAct.push(2)

      if(this.SolswitchHistorial)
        enfermAct.push(3)

      if(this.SolswitchHabitos)
        enfermAct.push(4)

      if(this.SolswitchAcLaboral)
        enfermAct.push(5)

      if(this.SolswitchOtro)
        enfermAct.push(6)

      if(this.SolswitchCiti)
        enfermAct.push(7)        
      
      var enActivos = enfermAct.toString()
      var objRequestRegistraCuentasBancarias = {
        strFolioCaso: this.id,
        strApiKey:this.key,
        strMetodo:'RegistraAseguradora',
        objSolicitante:{
          EMail:this.email,
          Peso:this.peso,
          Estatura:this.estatura,
          IdCaso:this.id,
          Activos:enActivos,
          AsignaAct:1,
          DatosAseguradora:enfermedades,
          TipoSeguro: this.tipoSeguro,
          SeguroDanos: this.seguroDanos,
          SeguroMontoCiti:this.SeguroMontoCiti,
          SeguroInsolutoCiti:this.SeguroInsolutoCiti,
          SeguroDanosCiti: this.SeguroDanosCiti,
          NombreAsesor: this.adicionales.nombreAsesor,
          RfcAsesor: this.adicionales.rfcAsesor,
          FechaSolicitud:this.adicionales.FechaSolicitud,
        }
      }
      this.$axios.post('/',objRequestRegistraCuentasBancarias,{headers : {'Content-Type':'application/x-www-form-urlencoded; charset=UTF-8'}})
      .then(
        response => {
          if(response.data.intEstatus == 0){
            this.$vs.notify({
              title:`Datos guardados exitosamente`,
              position:'top-right'
            })
            this.dameSolicitante()
          }else {
            this.$vs.notify({
              title:'Ocurrio un error en RegistraAseguradora',
              text:response.data.strError,
              color:'danger',
              position:'top-right'
            })
          }
        }
      ).catch(function (error) {
        self.$vs.notify({
          title:'Ocurrio un error de sistema',
          text:error,
          color:'danger',
          position:'top-right'
        })
      })
    },
    validaswitch(act){

      const words = act.split(',');

      for (var i = 0; i < words.length; i++) {
            
        if(words[i]==1){
          this.SolswitchAfecMedicas = true
          $('#Afecciones').show()
        }
        if(words[i]==2){
          this.SolswitchProMedicos = true
          $('#Procedimientos').show()
        }
        if(words[i]==3){
          this.SolswitchHistorial = true
          $('#Historial').show()
        }
        if(words[i]==4){
          this.SolswitchHabitos = true
          $('#Habitos').show()
        }
        if(words[i]==5){
          this.SolswitchAcLaboral = true
          $('#AcLaboral').show()
        }
        if(words[i]==6){
          this.SolswitchOtro = true
          $('#Otro').show()
        }
        if(words[i]==7){
          this.SolswitchCiti = true
          $('#Citi').show()
        }
        
      }
    }
  }
}
</script>


<style lang="css" scoped>
.input-antiguedad-am {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.input-antiguedad-fecha {
  margin-top: 10px;
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
  background-color: lightgrey;
}
.input-antiguedad-fecha span {
  color: inherit;
  font-size: medium;
  margin: 0;
  text-align: center;
  padding-top: 6px;
  display: block;
}

.fuente_check{
  font-size: 10px;
}
.size-example .vs-input{
  margin: 3px;
}

.content-radio {
  display: flex;
  justify-content: center;
  align-items: center;
}
.size-example .vs-input{
    margin: 3px;
}
</style>