<template lang="html">
	<div class="" id="container">
		<div class="">
			<div class="modal-container" v-if="popupConfirm">
				<div class="modal-dialog modal-dialog-centered modal-md" role="document">
					<div class="modal-content">
						<div class="modal-header title">
							<h3>Consulta buró de crédito</h3>
						</div>

						<div class="modal-body border-solid">
							<div class="row m-4">
								<div class="col-12 mb-4">
									<h4>¿Estás seguro de que deseas consultar buró de crédito?</h4>
								</div>
								<div class="col-6 text-center">
									<button class="btn btn-success" type="button" @click="ConsultarBuro()">Aceptar</button>
								</div>
								<div class="col-6 text-center">
									<button class="btn btn-red" type="button" @click="Cancelar()">Cancelar</button>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>

			<div class="modal-container" v-if="popupAlert">
				<div class="modal-dialog modal-dialog-centered modal-lg" role="document">
					<div class="modal-content">
						<div class="modal-header title">
							<h3>Alerta</h3>
						</div>

						<div class="modal-body">
							<div class="row m-4">
								<div class="col-12 mb-4">
									<h4>{{ msgAlert }}</h4>
								</div>
								<div class="col-12 text-center">
									<button class="btn btn-success" type="button" @click="Cancelar()">Aceptar</button>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>

			<div class="modal-container" v-if="popupActive">
				<div class="modal-dialog modal-dialog-centered modal-xl" role="document">
					<div class="modal-content">
						<div class="modal-header title">
							<h3>Datos Adicionales</h3>
						</div>

						<div class="modal-body">
							<form>
								<div class="row p-4">
									<div class="col-12 mb-4">
										<h4>¿El acreditado cuenta con direcciones adicionales?</h4>
										<label><span class="text-danger">*</span> Estos datos se solicitan por el banco para generar un resultado más preciso</label>
									</div>
									<div class="col-6 text-center">
										<input type="radio" name="direccionesAdicionales" v-model="direccionesAdicionales" id="direcciones-adicionales-si" value="SI">
										<label for="direcciones-adicionales-si">SI</label>
									</div>
									<div class="col-6 text-center">
										<input type="radio" name="direccionesAdicionales" v-model="direccionesAdicionales" id="direcciones-adicionales-no" value="NO">
										<label for="direcciones-adicionales-no">NO</label>
									</div>
								</div>

								<div class="mt-4" id="content-direcciones" v-if="isAdicionales">
									<div class="title p-4">
										<h3>Direcciones Adicionales</h3>
									</div>
									<div class="item-direccion p-4">
										<div class="row">
											<div class="col-12 mb-4">
												<label>Dirección 1:</label>
											</div>
										</div>

										<div class="row px-4">
											<div class="col-6 mb-3">
												<span class="span-placeholder">Calle *</span>
												<input type="text" class="vs-input" v-validate="'required'" name="CalleUno" v-model="CalleUno">
												<span class="text-danger text-sm" v-show="errors.has('CalleUno')">Campo requerido</span>
											</div>

											<div class="col-3 mb-3">
												<span class="span-placeholder">No. Exterior *</span>
												<input type="text" class="vs-input" v-validate="'required'" name="NoExtUno" v-model="NoExtUno">
												<span class="text-danger text-sm" v-show="errors.has('NoExtUno')">Campo requerido</span>
											</div>

											<div class="col-3 mb-3">
												<span class="span-placeholder">No. Interior</span>
												<input type="text" class="vs-input" name="NoIntUno" v-model="NoIntUno">
											</div>

											<div class="col-6 mb-3">
												<span class="span-placeholder">Colonia *</span>
												<input type="text" class="vs-input" v-validate="'required'" name="ColoniaUno" v-model="ColoniaUno">
												<span class="text-danger text-sm" v-show="errors.has('ColoniaUno')">Campo requerido</span>
											</div>

											<div class="col-6 mb-2">
												<span class="span-placeholder">Delegación/Municipio *</span>
												<input type="text" class="vs-input" v-validate="'required'" name="CdDelUno" v-model="CdDelUno">
												<span class="text-danger text-sm" v-show="errors.has('CdDelUno')">Campo requerido</span>
											</div>

											<div class="col-6 mb-2">
												<span class="span-placeholder">Estado *</span>
												<select name="cboEstadoUno" id="cboEstadoUno" v-model="cboEstadoUno" v-validate="'required'" class="vs-input">
													<option :value='estado.value' v-for="estado in listEstados" :key="">{{estado.nombre}}</option>
												</select>
												<span class="text-danger text-sm" v-show="errors.has('cboEstadoUno')">Campo requerido</span>
											</div>

											<div class="col-6 mb-2">
												<span class="span-placeholder">Código Postal *</span>
												<input type="text" class="vs-input" v-validate="'min:5|max:5'" v-mask="'#####'" v-on:keypress="isNumber($event)" name="CPUno" v-model="CPUno">
												<!-- <vs-input class="" v-model="CPUno" name="CPUno" v-validate="'required|min:5|max:5'" v-mask="'#####'" v-on:keypress="isNumber($event)" /> -->
												<span class="text-danger text-sm" v-show="errors.has('CPUno')">El campo debe tener 13 caracteres</span>
											</div>
										</div>
									</div>

									<hr>

									<div class="item-direccion p-4">
										<div class="row">
											<div class="col-12 mb-4">
												<label>Dirección 2:</label>
											</div>
										</div>

										<div class="row px-4">
											<div class="col-6 mb-3">
												<span class="span-placeholder">Calle</span>
												<input type="text" class="vs-input" name="calle" v-model="CalleDos">
											</div>

											<div class="col-3 mb-3">
												<span class="span-placeholder">No. Exterior</span>
												<input type="text" class="vs-input" name="noExterior" v-model="NoExtDos">
											</div>

											<div class="col-3 mb-3">
												<span class="span-placeholder">No. Interior</span>
												<input type="text" class="vs-input" name="noInterior" v-model="NoIntDos">
											</div>

											<div class="col-6 mb-3">
												<span class="span-placeholder">Colonia</span>
												<input type="text" class="vs-input" name="colonia" v-model="ColoniaDos">
											</div>

											<div class="col-6 mb-2">
												<span class="span-placeholder">Delegación/Municipio</span>
												<input type="text" class="vs-input" name="municipio" v-model="CdDelDos">
											</div>

											<div class="col-6 mb-2">
												<span class="span-placeholder">Estado</span>
												<select name="cboEstadoDos" id="cboEstadoDos" v-model="cboEstadoDos" class="vs-input">
													<option :value='estado.value' v-for="estado in listEstados" :key="">{{estado.nombre}}</option>
												</select>
											</div>

											<div class="col-6 mb-2">
												<span class="span-placeholder">Código Postal</span>
												<input type="text" class="vs-input" v-validate="'min:5|max:5'" v-mask="'#####'" v-on:keypress="isNumber($event)" name="cp" v-model="CPDos">
												<span class="text-danger text-sm" v-show="errors.has('cboEstadoDos')">El campo debe tener 13 caracteres</span>
											</div>
										</div>
									</div>
								</div>
							</form>
						</div>

						<div class="modal-footer">
							<button class="btn btn-success" type="button" @click="GuardarAdicionales()">Guardar</button>
							<button class="btn btn-red" type="button" @click="Cancelar()">Cancelar</button>
						</div>
					</div>
				</div>
			</div>
		</div>

		<vx-card class="formulario">
			<h4 class="titulo">Historial precalificaciones</h4>

			<br />
			<VueGoodTable 
				styleClass="vgt-table bordered" 
				:columns="columns" 
				:rows="rows" 
				:pagination-options="{
					enabled: true,
					mode: 'records',
					perPageDropdown: [5, 25],
					dropdownAllowAll: false,
					rowsPerPageLabel: 'Mostrar',
					ofLabel: 'registros de un total de',
					pageLabel: 'page', // for 'pages' mode
					nextLabel: 'siguiente',
					prevLabel: 'anterior',
				}">
				<div slot="emptystate">
					<center>No se han encontrado resultados para tu búsqueda</center>
				</div>
				<template slot="table-row" slot-scope="props">
					<span v-if="props.column.field == 'Activo'">
						<div class="rows-2">
							<span style="font-weight: 500; color: #49a84f">{{ props.row.Folio }}</span>
						</div>
						<div class="line-divider"></div>
						<div class="rows-2">
							<span>{{ props.row.textBuro }}</span>
						</div>
					</span>
					<span v-if="props.column.field == 'fechasRegistro'">
						<div class="rows-2">
							<div class="rows-2">
								<span style="font-weight: 500; color: #49a84f">{{ JSON.parse(props.row.fechas).fechaPrecalificacion }}</span>
							</div>
							<div class="line-divider"></div>
							<div class="rows-2">
								<span style="font-weight: 500; color: #49a84f">{{ JSON.parse(props.row.fechas).fechaConsultaBuro }}</span>
							</div>
						</div>
					</span>
					<span v-if="props.column.field == 'Acciones'">
						<div class="content-buttons">
							<button class="btn btn-success btn-table" @click="RegistrarAdicionales(props.row.idRegistro)">{{ props.row.textButtonAdicionales }}</button>
							<button v-if="isSupuervisor" class="btn btn-success btn-table" :disabled="!JSON.parse(props.row.isDisabled)" @click="ConfirmarBuro(props.row.idRegistro)">{{ props.row.textButtonBuro }}</button>
						</div>
					</span>

					<span v-else>
						{{ props.formattedRow[props.column.field] }}
					</span>
				</template>

				<!-- <template slot="table-header-row" slot-scope="props">
          <span v-if="props.column.field == 'action'">
            <button class="fancy-btn">Action</button>
          </span>
          <span v-else>
            {{ props.formattedRow[props.column.field] }}
          </span>
        </template> -->
			</VueGoodTable>

			<!-- Adicionales -->
			<!-- <vs-popup fullscreen title="Datos Adicionales" :active.sync="popupActive">
				<vx-card title="Datos requeridos" class="">
					
					<h4>Adicionales</h4>
					<br />
					<div class="row w-full">
						<div class="col-md-4">
							<vs-button color="primary" @click="editarIngreso()">Editar ingreso</vs-button>
						</div>
					</div>
				</vx-card>
			</vs-popup> -->
		</vx-card>
	</div>
			<!-- Adicionales -->
			<!-- <vs-popup fullscreen title="Datos Adicionales" :active.sync="popupActive">
				<vx-card title="Datos requeridos" class="">
					
					<h4>Adicionales</h4>
					<br />
					<div class="row w-full">
						<div class="col-md-4">
							<vs-button color="primary" @click="editarIngreso()">Editar ingreso</vs-button>
						</div>
					</div>
				</vx-card>
			</vs-popup> -->
		</vx-card>
	</div>
</template>


<script>
import VuePassword from 'vue-password'
import 'vue-good-table/dist/vue-good-table.css'
import { VueGoodTable } from 'vue-good-table'
import { hidden } from 'caniuse-lite/data/features'
export default {
	components: {
		VuePassword,
		VueGoodTable,
	},
	data() {
		return {
			id: '',
			key: '',
			emailBroker: '',
			email: '',
			cantidades: [
				{ Nombre: '15', id: 15 },
				{ Nombre: '25', id: 25 },
			],
			contrasenias: [],
			cantidad: 15,
			mostrarTabla: true,
			mostrarFormulario: false,
			financiera: '',
			usuario: '',
			password: '',
			estatus: false,
			fechaAlta: '',
			ultimaActualizacion: '',
			editar: true,
			textoTiluto: 'Nueva contraseña de banco',
			isSupuervisor: false,
			columns: [
				{
					label: 'Folio comparador',
					field: 'folioComparador',
					sortable: false,
					filterOptions: {
						styleClass: 'class1', // class to be added to the parent th element
						enabled: true, // enable filter for this column
						placeholder: 'Folio', // placeholder for filter input
						filterValue: '', // initial populated value for this filter
						//filterFn: this.columnFilterFn, //custom filter function that
					},
				},
				{
					label: 'Destino',
					field: 'destino',
					sortable: false,
					filterOptions: {
						styleClass: 'class1', // class to be added to the parent th element
						enabled: true, // enable filter for this column
						placeholder: 'Destino', // placeholder for filter input
						filterValue: '', // initial populated value for this filter
						//filterFn: this.columnFilterFn, //custom filter function that
					},
				},
				{
					label: 'Banco',
					field: 'banco',
					sortable: false,
					filterOptions: {
						styleClass: 'class1', // class to be added to the parent th element
						enabled: true, // enable filter for this column
						placeholder: 'Banco', // placeholder for filter input
						filterValue: '', // initial populated value for this filter
						//filterFn: this.columnFilterFn, //custom filter function that
					},
				},
				{
					label: 'Monto solicitado',
					field: 'montoSolicitado',
					sortable: true,
				},
				{
					label: 'Fecha solicitud',
					field: 'fechasRegistro',
					sortable: false,
				},
				{
					label: 'Estatus',
					field: 'Activo',
					sortable: false,
					filterOptions: {
						styleClass: 'class1', // class to be added to the parent th element
						enabled: true, // enable filter for this column
						placeholder: 'Estatus', // placeholder for filter input
						filterValue: '', // initial populated value for this filter
						//filterFn: this.columnFilterFn, //custom filter function that
					},
				},
				{
					label: 'Acciones',
					field: 'Acciones',
					sortable: false,
				},
			],
			rows: [],
			bancosEjecutivo: [],

			listEstados: [
				{ value: 0, nombre: 'Seleccione una opción' },
				{ value: 'AC', nombre: 'AGUASCALIENTES' },
				{ value: 'BC', nombre: 'BAJA CALIFORNIA' },
				{ value: 'BL', nombre: 'BAJA CALIFORNIA SUR' },
				{ value: 'CP', nombre: 'CAMPECHE' },
				{ value: 'CQ', nombre: 'CHIAPAS' },
				{ value: 'CJ', nombre: 'CHIHUAHUA' },
				{ value: 'OU', nombre: 'COAHUILA DE ZARAGOZA' },
				{ value: 'CW', nombre: 'COLIMA' },
				{ value: 'DI', nombre: 'DISTRITO FEDERAL' },
				{ value: 'DU', nombre: 'DURANGO' },
				{ value: 'GJ', nombre: 'GUANAJUATO' },
				{ value: 'UE', nombre: 'GUERRERO' },
				{ value: 'HD', nombre: 'HIDALGO' },
				{ value: 'JA', nombre: 'JALISCO' },
				{ value: 'EM', nombre: 'MEXICO' },
				{ value: 'IC', nombre: 'MICHOACAN DE OCAMPO' },
				{ value: 'RL', nombre: 'MORELOS' },
				{ value: 'AY', nombre: 'NAYARIT' },
				{ value: 'VO', nombre: 'NUEVO LEON' },
				{ value: 'OA', nombre: 'OAXACA' },
				{ value: 'UB', nombre: 'PUEBLA' },
				{ value: 'QU', nombre: 'QUERETARO ARTEAGA' },
				{ value: 'QR', nombre: 'QUINTANA ROO' },
				{ value: 'SP', nombre: 'SAN LUIS POTOSI' },
				{ value: 'NS', nombre: 'SINALOA' },
				{ value: 'ON', nombre: 'SONORA' },
				{ value: 'TA', nombre: 'TABASCO' },
				{ value: 'TS', nombre: 'TAMAULIPAS' },
				{ value: 'TL', nombre: 'TLAXCALA' },
				{ value: 'VZ', nombre: 'VERACRUZ' },
				{ value: 'YN', nombre: 'YUCATAN' },
				{ value: 'ZS', nombre: 'ZACATECAS' },
			],

			popupConfirm: false,
			popupActive: false,

			popupAlert: false,
			msgAlert: '',

			idSolicitud: '',

			direccionesAdicionales: '',
			isAdicionales: false,

			CalleUno: '',
			NoExtUno: '',
			NoIntUno: '',
			ColoniaUno: '',
			CdDelUno: '',
			cboEstadoUno: '0',
			CPUno: '',
			CalleDos: '',
			NoExtDos: '',
			NoIntDos: '',
			ColoniaDos: '',
			CdDelDos: '',
			cboEstadoDos: '0',
			CPDos: '',
			//bancos:[],
			// ActualizaTable: '',
		}
	},
	props: {
		ActualizaTable: {
			type: String,
			required: false,
		},
	},
	watch: {
		ActualizaTable: function () {
			if (this.ActualizaTable == 'actualiza') {
				this.rows = []
				this.gettabla()
			}
		},
		direccionesAdicionales: function () {

			this.isAdicionales = this.direccionesAdicionales === 'SI' && true

		}
	},

	mounted() {
		this.id = this.$route.params.id
		this.key = JSON.parse(localStorage.getItem('userInfo')).ApiKey
		this.emailBroker = JSON.parse(localStorage.getItem('userInfo')).EMail
		this.getPerfilBanco()
		this.gettabla()
	},
	methods: {
    RegistrarAdicionales(idSolicitud) {

			this.popupActive = true

			this.idSolicitud = idSolicitud
			this.getAdicionalesSolicitud(idSolicitud)
    },
		gettabla() {
			// alert('anali')
			let self = this
			var objRequestListaTabla = {
				strApiKey: this.key,
				strMetodo: 'ListaSolicitudesExternas',
				objCaso: {
					IdCaso: this.id,
				},
			}
			this.$axios
				.post('/', objRequestListaTabla, { headers: { 'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8' } })
				.then((response) => {
					this.ActualizaTable = ''
					const registro = response.data.objContenido

          this.rows = registro.map(element => {

            const fechaPreca = element.Fecha.date.substring(0, 10)
            const arrFechaPreca = fechaPreca.split('-', 3)
            const fechaPrecalificacion = arrFechaPreca[2] + '/' + arrFechaPreca[1] + '/' + arrFechaPreca[0]

            const fechaBuro = element.FechaConsultaBuro ? element.FechaConsultaBuro.date.substring(0, 10) : ''
            const arrFechaBuro = fechaBuro ? fechaBuro.split('-', 3) : ''
            const fechaConsultaBuro = fechaBuro ? arrFechaBuro[2] + '/' + arrFechaBuro[1] + '/' + arrFechaBuro[0] : ''

						let isDisabled
						if ( element.DatosAdicionales ) {

							if ( fechaConsultaBuro ) {

								isDisabled = false

							}
							else {

								isDisabled = true

							}

						}
						else {

							isDisabled = false

						}

            return {

              idRegistro: element.Id,
              folioComparador: element.Precalificacion, 
              destino: element.Destino, 
              banco: element.NombreBanco, 
              montoSolicitado: element.MontoSolicitado, 
              fechas: JSON.stringify({ fechaPrecalificacion, fechaConsultaBuro }),
							textBuro: fechaConsultaBuro ? '' : 'Sin buró de crédito',
							textButtonAdicionales: element.DatosAdicionales ? 'Consultar datos' : 'Datos adicionales',
							textButtonBuro: fechaConsultaBuro ? 'Se ha consultado buró' : 'Consultar buró de crédito',
              // Activo: element.Estatus, 
              Folio: element.FolioTexto,
							isDisabled

            }

          })

				})
				.catch(function (error) {
					self.$vs.notify({
						title: 'Ocurrio un error de sistema4',
						text: error,
						color: 'danger',
						position: 'top-right',
					})
				})
		},
		getPerfilBanco() {

			let self = this
			const objRequestBroker = {
				strApiKey: this.key,
				strMetodo: 'DamePerfilBrokerBanco',
				objBrokerBanco: {
					emailBroker: this.emailBroker,
					banco: 11
				},
			}

			// console.log('DamePerfilBrokerBanco')

			this.$axios
				.post('/', objRequestBroker, { headers: { 'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8' } })
				.then((response) => {

					const intEstatus = response.data.intEstatus

					if ( intEstatus ) {

						return this.$vs.notify({
							title: 'Ocurrio un error en DamePerfilBrokerBanco',
							text: data.strError,
							color: 'danger',
							position: 'top-right',
						})

					}

					const resp = response.data.objContenido

					this.isSupuervisor = resp === 'Supervisor' && true

				})
				.catch(function (error) {
					self.$vs.notify({
						title: 'Ocurrio un error de sistema4',
						text: error,
						color: 'danger',
						position: 'top-right',
					})
				})

		},
		Cancelar() {

			this.idSolicitud = ''
			this.popupActive = false

			this.direccionesAdicionales = ''
			this.isAdicionales = false

			this.popupConfirm = false

			this.popupAlert = false

		},
		getAdicionalesSolicitud(idSolicitud) {

			let self = this
			const objRequestBroker = {
				strApiKey: this.key,
				strMetodo: 'DameAdicionalesSolicitud',
				objSolicitud: { idSolicitud }
			}

			this.$axios
				.post('/', objRequestBroker, { headers: { 'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8' } })
				.then((response) => {

					const intEstatus = response.data.intEstatus

					if ( intEstatus ) {

						return this.$vs.notify({
							title: 'Ocurrio un error en DameAdicionalesSolicitud',
							text: data.strError,
							color: 'danger',
							position: 'top-right',
						})

					}

					const { datosAdicionales, direccionesAdicionales } = response.data.objContenido

					this.direccionesAdicionales = datosAdicionales
					const objDireccionesAdicionales = JSON.parse(direccionesAdicionales)

					this.CalleUno = direccionesAdicionales ? objDireccionesAdicionales.CalleUno : ''
					this.NoExtUno = direccionesAdicionales ? objDireccionesAdicionales.NoExtUno : ''
					this.NoIntUno = direccionesAdicionales ? objDireccionesAdicionales.NoIntUno : ''
					this.ColoniaUno = direccionesAdicionales ? objDireccionesAdicionales.ColoniaUno : ''
					this.CdDelUno = direccionesAdicionales ? objDireccionesAdicionales.CdDelUno : ''
					this.cboEstadoUno = direccionesAdicionales ? objDireccionesAdicionales.cboEstadoUno : ''
					this.CPUno = direccionesAdicionales ? objDireccionesAdicionales.CPUno : ''

					this.CalleDos = direccionesAdicionales ? objDireccionesAdicionales.CalleDos : ''
					this.NoExtDos = direccionesAdicionales ? objDireccionesAdicionales.NoExtDos : ''
					this.NoIntDos = direccionesAdicionales ? objDireccionesAdicionales.NoIntDos : ''
					this.ColoniaDos = direccionesAdicionales ? objDireccionesAdicionales.ColoniaDos : ''
					this.CdDelDos = direccionesAdicionales ? objDireccionesAdicionales.CdDelDos : ''
					this.cboEstadoDos = direccionesAdicionales ? objDireccionesAdicionales.cboEstadoDos : '0'
					this.CPDos = direccionesAdicionales ? objDireccionesAdicionales.CPDos : ''

				})
				.catch(function (error) {
					self.$vs.notify({
						title: 'Ocurrio un error de sistema',
						text: error,
						color: 'danger',
						position: 'top-right',
					})
				})

		},
		GuardarAdicionales() {

			let self = this
			this.$vs.loading({
				container: '#container',
				scale: 0.6,
			})

			if ( !this.direccionesAdicionales ) {

				return this.$vs.notify({
					title: 'Ocurrio un error',
					text: 'Debes seleccionar una opción',
					color: 'danger',
					position: 'top-right',
				})

			}

			if ( 
				this.direccionesAdicionales === 'SI' && 
				(
					!this.CalleUno ||
					!this.NoExtUno ||
					// !this.NoIntUno ||
					!this.ColoniaUno ||
					!this.CdDelUno ||
					!this.cboEstadoUno ||
					!this.CPUno 
				)
			) {

				return this.$vs.notify({
					title: 'Ocurrio un error',
					text: 'Debes ingresar los campos obligatorios de la direccion 1',
					color: 'danger',
					position: 'top-right',
				})

			}

			const direccionesAdicionales = {
				CalleUno: this.CalleUno,
				NoExtUno: this.NoExtUno,
				NoIntUno: this.NoIntUno,
				ColoniaUno: this.ColoniaUno,
				CdDelUno: this.CdDelUno,
				cboEstadoUno: this.cboEstadoUno,
				CPUno: this.CPUno,
				CalleDos: this.CalleDos,
				NoExtDos: this.NoExtDos,
				NoIntDos: this.NoIntDos,
				ColoniaDos: this.ColoniaDos,
				CdDelDos: this.CdDelDos,
				cboEstadoDos: this.cboEstadoDos,
				CPDos: this.CPDos,
			}

			const objRequestAdicionales = {
				strApiKey: this.key,
				strMetodo: 'GuardarAdicionalesSolicitud',
				objAdicionales: { 

					idSolicitud: this.idSolicitud,
					datosAdicionales: this.direccionesAdicionales,
					direccionesAdicionales: JSON.stringify(direccionesAdicionales)

				},
			}

			this.$axios
				.post('/', objRequestAdicionales, { headers: { 'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8' } })
				.then((response) => {

					const intEstatus = response.data.intEstatus
					this.$vs.loading.close('#container .con-vs-loading')

					if ( intEstatus ) {

						return this.$vs.notify({
							title: 'Ocurrio un error en DameAdicionalesSolicitud 1',
							text: response.data.strError,
							color: 'danger',
							position: 'top-right',
						})

					}

					const isAdicionales = response.data.objContenido.agregaAdicionales

					if ( !isAdicionales ) {

						return this.$vs.notify({
							title: 'Ocurrio un error en GuardarAdicionalesSolicitud 2',
							text: response.data.objContenido.strError,
							color: 'danger',
							position: 'top-right',
						})

					}

					this.$vs.notify({
						title: `Guardado exitosamente`,
						position: 'top-right',
					})

					this.popupActive = false

					this.gettabla()

				})
				.catch(function (error) {
					self.$vs.notify({
						title: 'Ocurrio un error de sistema4',
						text: error,
						color: 'danger',
						position: 'top-right',
					})
				})

		},

		ConfirmarBuro(idSolicitud) {

			this.idSolicitud = idSolicitud
			this.popupConfirm = true

		},

		ConsultarBuro() {

			let self = this
			this.$vs.loading({
				container: '#container',
				scale: 0.6,
			})

			const objRequesSolicitud = {
				strApiKey: this.key,
				strMetodo: 'ConsultarBuroSolicitud',
				objSolicitud: { idSolicitud: this.idSolicitud },
			}

			this.$axios
				.post('/', objRequesSolicitud, { headers: { 'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8' } })
				.then((response) => {

					const intEstatus = response.data.intEstatus
					this.$vs.loading.close('#container .con-vs-loading')

					if ( intEstatus ) {

						if ( intEstatus === 500 ) {

							this.msgAlert = response.data.strError
							this.popupAlert = true

						}

						return this.$vs.notify({
							title: 'Ocurrio un error en ConsultarBuroSolicitud',
							text: response.data.strError,
							color: 'danger',
							position: 'top-right',
						})

					}

					this.$vs.notify({
						title: `Guardado exitosamente`,
						position: 'top-right',
					})

					this.popupConfirm = false
					this.gettabla()

				})
				.catch(function (error) {
					self.$vs.notify({
						title: 'Ocurrio un error de sistema',
						text: error,
						color: 'danger',
						position: 'top-right',
					})
				})

		},
		isNumber: function (evt) {
			evt = evt ? evt : window.event
			var charCode = evt.which ? evt.which : evt.keyCode
			if (charCode > 31 && (charCode < 48 || charCode > 57) && charCode !== 46) {
				evt.preventDefault()
			} else {
				return true
			}
		},
	},
}
</script>

<style scoped>
.vx-card{
padding: 0; 
}
.btn-danger,
.btn-primary{
  width: 100%;
  border-radius: 20px;
  border: #626262 1px solid;
  padding: 0.55rem;
}

.btn-red {
	background: #ff3f3f;
	color: #fff;
}

.btn-red:hover {
	background: #e03535;
}

.rows-2 {
  min-height: 48px;
}

.line-divider {
  width: 100%;
  height: 1px;
  margin-top: 0.75em;
  margin-bottom: 0.75em;
  border-bottom: solid #286090 1px;
}

.content-buttons {
  display: flex;
  flex-direction: column;
  gap: 15px;
}

.content-buttons button {
  min-height: 50px;
  font-size: 12px;
}

.modal-content {
	border: none;
}

.modal-container {

	position: fixed;
	top: 0px;
	left: 0px;
	width: 100%;
	height: 100%;
	background: rgba(0, 0, 0, 0.1);
	border-radius: 5px;
	overflow-x: hidden;
	overflow-y: auto;
	z-index: 100000;
	border: none !important;

}

/* .modal-container .modal-header {

} */

.modal-container .title {
	background: #006D4E;
	color: #fff;
}

.modal-container .title h3 {
	color: #fff;
}

.modal-container .modal-header h3 {
	color: #fff;
}

.modal-container .modal-body {
	padding: 0;
}

.modal-container .modal-body.border-solid {
	border-radius: 0 0 5px 5px;
	border: solid 5px #006D4E;
	border-top: none !important;
}
.btn-red {
	background: #ff3f3f;
	color: #fff;
}

.btn-red:hover {
	background: #e03535;
}

.rows-2 {
  min-height: 48px;
}

.line-divider {
  width: 100%;
  height: 1px;
  margin-top: 0.75em;
  margin-bottom: 0.75em;
  border-bottom: solid #286090 1px;
}

.content-buttons {
  display: flex;
  flex-direction: column;
  gap: 15px;
}

.content-buttons button {
  min-height: 50px;
  font-size: 12px;
}

.modal-content {
	border: none;
}

.modal-container {

	position: fixed;
	top: 0px;
	left: 0px;
	width: 100%;
	height: 100%;
	background: rgba(0, 0, 0, 0.1);
	border-radius: 5px;
	overflow-x: hidden;
	overflow-y: auto;
	z-index: 100000;
	border: none !important;

}

/* .modal-container .modal-header {

} */

.modal-container .title {
	background: #006D4E;
	color: #fff;
}

.modal-container .title h3 {
	color: #fff;
}

.modal-container .modal-header h3 {
	color: #fff;
}

.modal-container .modal-body {
	padding: 0;
}

.modal-container .modal-body.border-solid {
	border-radius: 0 0 5px 5px;
	border: solid 5px #006D4E;
	border-top: none !important;
}

.modal-container .modal-body label {
	font-size: 1.2rem !important;
}
.modal-container .modal-body label {
	font-size: 1.2rem !important;
}
</style>